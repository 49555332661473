import { colors, TrendingHazardDTO } from '@hazadapt-git/public-core-base'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoLocationSharp } from 'react-icons/io5'
import { localContentColor } from '../../lib/styles/universal'

interface TrendingHazardItemProps extends TrendingHazardDTO {
    onPress(id: number): void
    fontShade: 'light' | 'dark'
}

export const TrendingHazardItem: FC<TrendingHazardItemProps> = (
    props: TrendingHazardItemProps
) => {
    const { classes: localClasses } = useLocalStyles()

    const titleId = `trending-hazard-${props.id}-name`

    return (
        <div
            className={localClasses.container}
            onClick={() => props.onPress(props.id)}
        >
            <img
                src={
                    props.icon.url ||
                    `data:image/svg+xml;base64,${btoa(props.icon.src)}`
                }
                alt={props.icon.alt}
                className={localClasses.icon}
            />
            <Typography
                fontWeight={500}
                textAlign="center"
                sx={{
                    textWrap: 'nowrap',
                    hyphens: 'auto',
                }}
                color={colors.primary.CERULEAN}
                id={titleId}
                lineHeight="1.375rem"
            >
                {props.name}
            </Typography>
            {props.local && <IoLocationSharp color={localContentColor} />}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'row',
        padding: '.25rem 1rem',
        gap: '1rem',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.grays.BLANC,
        border: `2px solid ${colors.secondary.HEAT_WAVE}`,
        borderRadius: '.5rem',
    },
    icon: {
        width: '2rem',
        height: '2rem',
        objectFit: 'contain',
    },
})
