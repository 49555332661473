import React, { FC } from 'react'
import { useWindowSizeUp } from '../../lib/utils'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { Typography } from '@mui/material'
import { SeatSubscriptionView } from '../molecules'
import { SimplifiedSeatSubscription } from '../../lib/hooks/useSubscription'
import { useSubscription } from '../../lib/hooks/useSubscription'

interface SeatSubscriptionTableProps {
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
}

export const SeatSubscriptionTable: FC<SeatSubscriptionTableProps> = ({
    onNavigateToCancelSeats,
}) => {
    const lgScreen = useWindowSizeUp('lg')
    const { simplifiedSeatSubscriptions: seatSubs } = useSubscription()

    const columns = lgScreen
        ? ['Purchase Date', 'Number of Seats', 'Renewal Date']
        : ['Number of Seats', 'Renewal Date']

    return (
        <>
            {seatSubs.length > 0 && (
                <TableContainer component={Paper} sx={{ maxHeight: '20rem' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((col) => (
                                    <TableCell
                                        key={col
                                            .toLowerCase()
                                            .replaceAll(' ', '-')}
                                    >
                                        <Typography
                                            textAlign={'center'}
                                            fontWeight={500}
                                        >
                                            {col}
                                        </Typography>
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {seatSubs.map((s) => (
                                <SeatSubscriptionView
                                    key={`seat-sub-${s.id}`}
                                    seatSub={s}
                                    onNavigateToCancelSeats={
                                        onNavigateToCancelSeats
                                    }
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}
