import React, { FC } from 'react'
import {
    OrganizationChangelogActions,
    OrganizationChangelogDTO,
    OrganizationChangeDTO,
    RPUser,
    colors,
    OrganizationWithMembers,
} from '@hazadapt-git/public-core-base'
import {
    Avatar,
    Box,
    Button,
    Pagination,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useStyles } from '../../lib/styles/universal'
import { NamespacedPageProps } from '../../lib/entities'
import { LoadingView } from '../molecules'
import { useWindowSizeUp } from '../../lib/utils'
import classNames from 'classnames'
import { format } from 'date-fns-tz'
import { permissionsMap } from '../organisms'
import { useNavigate } from 'react-router'

interface OrganizationChangelogPageTemplateProps
    extends Omit<NamespacedPageProps, 'organization'>,
        Omit<OrganizationChangelogDTO, 'changes'> {
    organization: OrganizationWithMembers
    changes?: OrganizationChangeDTO[]
    onPaginate(e: React.ChangeEvent<unknown>, page: number): void
}

const buildChangeDescription = (
    members: RPUser[],
    changes: OrganizationChangelogActions[]
): React.ReactNode => {
    const actionNodes: string[] = []
    for (const ch of changes) {
        switch (ch.op) {
            case 'add': {
                if (!ch.val) break
                if (ch.path[0] === 'members') {
                    const member = members.find(
                        (m) => m.rp_user_id === ch.path[1]
                    )
                    const memberName = member
                        ? `${member.first_name} ${member.last_name}`
                        : ch.val.name
                    if (ch.path[2] === 'permissions') {
                        actionNodes.push(
                            `Added new permission for ${memberName}: "${
                                permissionsMap.get(ch.val.value) ?? 'N/A'
                            }"`
                        )
                    } else {
                        actionNodes.push(
                            `Added new member: ${
                                (ch.val as RPUser).first_name
                            } ${(ch.val as RPUser).last_name}`
                        )
                    }
                } else if (ch.path[0] === 'guests') {
                    // actionNodes.push(
                    //     `Added new guest: ${(ch.val as User).first_name} ${
                    //         (ch.val as User).last_name
                    //     }`
                    // )
                    break
                } else if (ch.path[0] !== 'invites') {
                    actionNodes.push(
                        `Added ${ch.path[0].replaceAll(
                            '_',
                            ' '
                        )} with value set to ${ch.val}`
                    )
                    break
                }
                break
            }
            case 'update': {
                if (ch.path[0] === 'jurisdiction') {
                    actionNodes.push('Updated jurisdiction')
                } else if (ch.path[0] === 'name') {
                    actionNodes.push(
                        `Updated organization name from ${ch.oldVal} to ${ch.val}`
                    )
                } else if (ch.path[0] === 'logo') {
                    actionNodes.push('Updated organization logo')
                } else if (ch.path[0] === 'members') {
                    const member = members.find(
                        (m) => m.rp_user_id === ch.path[1]
                    )
                    const memberName = member
                        ? `${member.first_name} ${member.last_name}`
                        : ch.val.name
                    if (ch.path[2] === 'permissions') {
                        actionNodes.push(
                            `Removed "${
                                permissionsMap.get(ch.oldVal.value) ?? 'N/A'
                            }" permission for ${memberName} and added "${
                                permissionsMap.get(ch.val.value) ?? 'N/A'
                            }"`
                        )
                    } else if (ch.path[2] === 'status') {
                        if (ch.val === 'deactivated') {
                            actionNodes.push(`Deactivated ${memberName}`)
                        } else {
                            actionNodes.push(
                                `Marked ${memberName} as ${ch.val.value}`
                            )
                        }
                    } else {
                        if (ch.oldVal) {
                            actionNodes.push(
                                `Updated ${ch.path[2]
                                    .toString()
                                    .replaceAll(
                                        '_',
                                        ' '
                                    )} for ${memberName} from ${ch.oldVal} to ${
                                    ch.val.value
                                }`
                            )
                        } else {
                            actionNodes.push(
                                `Updated ${ch.path[2]
                                    .toString()
                                    .replaceAll(
                                        '_',
                                        ' '
                                    )} for ${memberName} to ${ch.val.value}`
                            )
                        }
                    }
                } else if (ch.path[0] === 'seats') {
                    if (ch.oldVal === ch.val) break
                    if (ch.val > ch.oldVal) {
                        actionNodes.push(
                            `Purchased ${
                                ch.val - ch.oldVal
                            } more organization seats.`
                        )
                    } else {
                        actionNodes.push(
                            `Canceled ${ch.oldVal - ch.val} organization seats.`
                        )
                    }
                } else {
                    if (ch.oldVal) {
                        actionNodes.push(
                            `Updated ${ch.path[0]
                                .toString()
                                .replaceAll('_', ' ')} from ${ch.oldVal} to ${
                                ch.val
                            }`
                        )
                    } else {
                        actionNodes.push(
                            `Updated ${ch.path[0]
                                .toString()
                                .replaceAll('_', ' ')} to ${ch.val}`
                        )
                    }
                }
                break
            }
            case 'delete': {
                if (ch.path[0] === 'members') {
                    if (ch.path[2] === 'permissions') {
                        const member = members.find(
                            (m) => m.rp_user_id === ch.path[1]
                        )
                        const memberName = member
                            ? `${member.first_name} ${member.last_name}`
                            : ch.oldVal.name
                        actionNodes.push(
                            `Removed "${
                                permissionsMap.get(ch.oldVal.value) ?? 'N/A'
                            }" permission for ${memberName}`
                        )
                    } else {
                        actionNodes.push(
                            `Removed ${ch.oldVal.name} from organization`
                        )
                    }
                }
                break
            }
            default: {
                break
            }
        }
    }
    return actionNodes.length > 0 ? (
        <div>
            {actionNodes.map((n, index) => (
                <Typography key={`node-${index}`}>{n}</Typography>
            ))}
        </div>
    ) : null
}

export const OrganizationChangelogPageTemplate: FC<
    OrganizationChangelogPageTemplateProps
> = (props: OrganizationChangelogPageTemplateProps) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')
    const lgScreens = useWindowSizeUp('lg')
    const navigate = useNavigate()

    const columns = ['User', 'Change', 'Date Changed']

    const goToOrgSettings = () => {
        navigate('/settings/organization')
    }

    return props.organization && props.user ? (
        <div className={classNames(classes.body, localClasses.container)}>
            <div className={localClasses.header}>
                <Typography component="h1" variant={mdScreens ? 'h2' : 'h3'}>
                    Organization Change History
                </Typography>
                <Button onClick={goToOrgSettings} sx={{ marginRight: '1rem' }}>
                    Return to Organization Settings
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell
                                    key={col.toLowerCase().replaceAll(' ', '-')}
                                >
                                    {col}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.changes ? (
                            props.changes.map((ch, index) => {
                                const timestamp = format(
                                    new Date(ch.date_changed),
                                    `MMMM d, y'\n'h:mm a`
                                )
                                const changeDescription =
                                    buildChangeDescription(
                                        props.organization.members,
                                        ch.actions
                                    )
                                if (!changeDescription) return null
                                return (
                                    <TableRow key={ch.id}>
                                        {/* User */}
                                        <TableCell>
                                            <div
                                                className={
                                                    localClasses.rowContainer
                                                }
                                            >
                                                {lgScreens ? (
                                                    <Avatar
                                                        sx={{
                                                            height: '2.5rem',
                                                            width: '2.5rem',
                                                            bgcolor: ch.icon
                                                                ? undefined
                                                                : colors.grays
                                                                      .CUMULUS,
                                                        }}
                                                        src={ch.icon}
                                                    >
                                                        {ch.icon ||
                                                        ch.user_name.length ===
                                                            0
                                                            ? null
                                                            : ch.user_name[0]}
                                                    </Avatar>
                                                ) : null}
                                                <Typography>
                                                    {ch.user_name}
                                                </Typography>
                                            </div>
                                        </TableCell>

                                        {/* Change Description */}
                                        <TableCell>
                                            {changeDescription}
                                        </TableCell>

                                        {/* Timestamp */}
                                        <TableCell>
                                            {timestamp
                                                .split('\n')
                                                .map((chunk, idx) => (
                                                    <Box
                                                        key={`timestamp-chunk-${
                                                            idx + 1
                                                        }`}
                                                    >
                                                        {chunk}
                                                    </Box>
                                                ))}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <ChangelogSkeleton />
                        )}
                        {}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.num_pages > 1 ? (
                <Pagination
                    count={props.num_pages}
                    page={props.page}
                    onChange={props.onPaginate}
                    siblingCount={1}
                    className={localClasses.pagination}
                />
            ) : null}
        </div>
    ) : (
        <LoadingView />
    )
}

const ChangelogSkeleton = () => {
    const lgScreens = useWindowSizeUp('lg')

    return (
        <>
            {Array.from(Array(10)).map((_, index) => {
                // creates a random value and utilizes the quadratic fn to skew towards the extremes for better variation
                const randomNameWidth =
                    Math.floor(Math.pow(Math.random(), 2) * (100 - 30 + 1)) + 30
                const randomHazardWidth =
                    Math.floor(Math.pow(Math.random(), 2) * (70 - 30 + 1)) + 30

                return (
                    <TableRow
                        key={index}
                        sx={{ '&.MuiTableRow-root': { height: '5.1rem' } }}
                    >
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                {lgScreens && (
                                    <Box>
                                        <Skeleton variant="circular">
                                            <Avatar />
                                        </Skeleton>
                                    </Box>
                                )}
                                <Box sx={{ width: '100%' }}>
                                    <Skeleton width={`${randomNameWidth}%`} />
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Skeleton width={`${randomHazardWidth}%`} />
                            <Skeleton width="75%" />
                        </TableCell>
                        <TableCell>
                            <Skeleton width="65%" />
                            <Skeleton width="40%" />
                        </TableCell>
                        <TableCell>
                            <Skeleton width="75%" />
                        </TableCell>
                    </TableRow>
                )
            })}
        </>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-start',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1rem',
        width: '100%',
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    },
})
