import { MenuItem, ListItemIcon } from '@mui/material'
import { IoOpenOutline } from 'react-icons/io5'
import { MenuItemProps } from './constants'
import { makeStyles } from 'tss-react/mui'

export const CustomMenuItem = (item: MenuItemProps) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <MenuItem
            onClick={item.onClick}
            className={localClasses.menuItem}
            sx={item.sx}
            key={item.label.toLowerCase().replaceAll(' ', '-')}
        >
            {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            <span className={localClasses.label}>{item.label}</span>
            {item.external ? (
                <ListItemIcon sx={{ minWidth: 'unset !important' }}>
                    <IoOpenOutline />
                </ListItemIcon>
            ) : null}
        </MenuItem>
    )
}

const useLocalStyles = makeStyles()({
    label: { flex: 1 },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '0.5rem',
    },
})
