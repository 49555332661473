import React, { FC } from 'react'
import {
    SingleBarGraphInsight,
    MultiBarGraphInsight,
} from '@hazadapt-git/public-core-base'
import { Bar } from 'react-chartjs-2'
import { ChartData } from 'chart.js/dist/types/index'
import { truncateChartLabel } from '../../lib/utils'

interface SingleBarGraphInsightViewProps
    extends Omit<SingleBarGraphInsight, 'headline'> {
    headline: React.ReactNode
}

interface MultiBarGraphInsightViewProps
    extends Omit<MultiBarGraphInsight, 'headline'> {
    headline: React.ReactNode
}
export const BarGraphInsightView: FC<
    SingleBarGraphInsightViewProps | MultiBarGraphInsightViewProps
> = (props: SingleBarGraphInsightViewProps | MultiBarGraphInsightViewProps) => {
    const getSingleBarGraphItems = (
        data: SingleBarGraphInsightViewProps
    ): ChartData<'bar', number[], string> => {
        const labels = data.bars.map((b) => b.label)
        const dataset = {
            data: [] as number[],
            backgroundColor: [] as string[],
            borderColor: [] as string[],
            borderRadius: 6,
        }
        for (const bar of data.bars) {
            dataset.data.push(bar.value)
            dataset.backgroundColor.push(bar.color)
            dataset.borderColor.push(bar.color)
        }
        return { labels, datasets: [dataset] }
    }

    const getMultiBarGraphGroups = (
        data: MultiBarGraphInsightViewProps
    ): ChartData<'bar', number[], string> => {
        return {
            labels: data.labels,
            datasets: data.groups.map((group) => ({
                label: group.name,
                data: group.values,
                backgroundColor: group.color,
                borderColor: group.color,
                borderRadius: 6,
            })),
        }
    }

    const data = React.useMemo(
        () =>
            'bars' in props
                ? getSingleBarGraphItems(props)
                : getMultiBarGraphGroups(props),
        [props]
    )

    return (
        <Bar
            data={data}
            options={{
                aspectRatio: 1,
                indexAxis: props.orientation === 'vertical' ? 'x' : 'y',
                plugins: {
                    legend: {
                        display: 'groups' in props,
                    },
                },
                resizeDelay: 50,
                scales: {
                    x: {
                        stacked: 'groups' in props && props.stacked,
                        title: {
                            display: true,
                            text: props.axes.x,
                        },
                    },
                    y: {
                        stacked: 'groups' in props && props.stacked,
                        title: {
                            display: true,
                            text: props.axes.y,
                        },
                        ticks: {
                            precision: 0,
                            callback: function (value) {
                                return truncateChartLabel(
                                    value as number,
                                    10,
                                    this.getLabelForValue.bind(this)
                                )
                            },
                        },
                        beginAtZero: true,
                    },
                },
                // maintainAspectRatio: false,
            }}
        />
    )
}
