import React, { FC } from 'react'

import { LoadingView, OrganizationDetailsTemplate } from '../components'
import { NamespacedPageProps, NewRPUser } from '../lib/entities'
import {
    OrganizationWithMembers,
    RPUser,
    RPUserPermission,
    colors,
} from '@hazadapt-git/public-core-base'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCheckmarkCircle,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import {
    errorColor,
    primaryIconSize,
    successColor,
} from '../lib/styles/universal'
import {
    checkUserPermissions,
    inviteUser,
    removeUser,
    resendInvitation,
    toast,
    updateOrganization,
    updateOrganizationLogo,
} from '../lib/utils'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import { OrganizationChangelogPage } from './OrganizationChangelog'
import { AxiosError } from 'axios'

interface OrganizationDetailsProps extends NamespacedPageProps {
    fullOrganization?: OrganizationWithMembers | null
    setOrganization: React.Dispatch<
        React.SetStateAction<OrganizationWithMembers | null | undefined>
    >
}

export const ManageOrganizationPage: FC<OrganizationDetailsProps> = ({
    user,
    loading,
    fullOrganization: organization,
    setOrganization,
}) => {
    const navigate = useNavigate()

    const onSaveLogoClick = React.useCallback(
        async (file: File) => {
            if (!organization) return
            try {
                const updatedOrg = await updateOrganizationLogo(file)
                setOrganization(updatedOrg)
            } catch (err) {
                console.error(err)
            }
        },
        [organization, setOrganization]
    )

    const propagateChanges = React.useCallback(
        async (organization: OrganizationWithMembers) => {
            toast(
                'Saving...',
                <IoCloudUploadOutline
                    color={colors.primary.BLUEBERRY}
                    size={primaryIconSize}
                />
            )
            try {
                const updatedOrg = await updateOrganization(organization)
                setOrganization(updatedOrg)
                toast(
                    'Changes saved!',
                    <IoCheckmark size={primaryIconSize} color={successColor} />
                )
            } catch (err) {
                const error = err as AxiosError
                console.error(error)
                toast(
                    error.message,
                    <IoAlertCircle size={primaryIconSize} color={errorColor} />
                )
            }
        },
        [setOrganization]
    )

    const onSaveUserChanges = React.useCallback(
        async (user: RPUser) => {
            if (!organization) return
            const members = [...organization.members]
            const idx = members.findIndex(
                (m) => m.rp_user_id === user.rp_user_id
            )
            if (idx < 0) return
            members[idx] = {
                ...members[idx],
                ...user,
            }
            await propagateChanges({
                ...organization,
                members,
            })
        },
        [organization, propagateChanges]
    )

    const onSubmitNewUser = React.useCallback(
        async (newUser: NewRPUser) => {
            if (!organization) return
            const newUserArray = [newUser]
            inviteUser(newUserArray)
                .then((members) => {
                    toast(
                        `${newUser.first_name} ${newUser.last_name} has been invited to your organization.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                    const org = { ...organization }
                    setOrganization({
                        ...org,
                        members,
                    })
                })
                .catch((err) => {
                    console.error(err)
                    toast(
                        `There was a problem inviting ${newUser.first_name} ${newUser.last_name} to your organization. Please try again.`,
                        <IoAlertCircle
                            color={errorColor}
                            size={primaryIconSize}
                        />
                    )
                })
        },
        [organization, setOrganization]
    )
    const handleDeleteUser = React.useCallback(
        async (user: RPUser) => {
            if (!organization) return
            try {
                const newMemberList = await removeUser([user.rp_user_id])
                if (newMemberList) {
                    const org = { ...organization }
                    setOrganization({
                        ...org,
                        members: newMemberList,
                    })
                    toast(
                        `${user.first_name} ${user.last_name} has been removed from your organization.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                }
            } catch (e) {
                toast(
                    `There was a problem removing ${user.first_name} ${user.last_name} from your organization. Please try again.`,
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization, setOrganization]
    )

    const onResendInvite = React.useCallback(
        async (user: RPUser) => {
            if (!organization) return
            if (user.status === 'active') {
                toast(
                    `${user.first_name} ${user.last_name} has already joined the organization.`
                )
                return
            }
            if (user.status !== 'invited') return
            try {
                const members = await resendInvitation(user.rp_user_id)
                const org = { ...organization }
                setOrganization({
                    ...org,
                    members,
                })
            } catch (err) {
                console.error(err)
                toast(
                    'There was a problem re-sending the invitation. Please try again.',
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization, setOrganization]
    )

    React.useEffect(() => {
        document.title = checkUserPermissions(user, [
            RPUserPermission.EDIT_ORGANIZATION,
        ])
            ? 'Organization Settings - ResiliencePoint'
            : 'My Organization - ResiliencePoint'
    }, [user])

    const onGoToOrgChangelog = () =>
        navigate('/settings/organization/changelog')

    return user && organization ? (
        <Routes>
            <Route
                path=""
                element={
                    <OrganizationDetailsTemplate
                        activeUser={user}
                        organization={organization}
                        mode="manage"
                        onSaveLogoClick={onSaveLogoClick}
                        onSubmitNewUser={onSubmitNewUser}
                        onResendInvite={onResendInvite}
                        onRemoveUser={handleDeleteUser}
                        onSaveUserChanges={onSaveUserChanges}
                        onGoToOrgChangelog={onGoToOrgChangelog}
                    />
                }
            />
            <Route
                path="changelog"
                element={
                    <OrganizationChangelogPage
                        user={user}
                        organization={organization}
                        loading={loading}
                    />
                }
            />
            <Route
                path="*"
                element={<Navigate replace to="/settings/organization" />}
            />
        </Routes>
    ) : !loading && (user === null || organization === null) ? null : (
        <LoadingView />
    )
}
