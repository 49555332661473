import {
    Insight,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    SurveyAnswerInsight,
} from '@hazadapt-git/public-core-base'
import React, { FC, useMemo, useRef } from 'react'

import { QueryBuilderValues } from '../components'
import { NamespacedPageProps, RefreshInsightArgs } from '../lib/entities'
import {
    useBuildInsightGridData,
    refreshInsight,
    saveQuery,
    exportInsights,
    persistLocalPickerChangesOnNewConfig,
    getSingleInsight,
} from '../lib/utils/insights'
import { useAppSelector } from '../lib/store'
import { getHazardData, getPrepCheckData } from '../lib/utils/cms'
import { buildZipCodePickerItems, formatDateRange } from '../lib/utils'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { SurveyAnswerInsightTemplate } from '../components/templates/SurveyAnswerInsightTemplate'

interface SurveyAnswerInsightProps extends NamespacedPageProps {}

export const SurveyAnswerInsights: FC<SurveyAnswerInsightProps> = ({
    user,
    organization,
    loading,
}) => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [insightData, setInsightData] = React.useState<Insight[]>()
    const [activeQuery, setActiveQuery] = React.useState<InsightQueryDTO>()
    const [retrievingInsights, setRetrievingInsights] =
        React.useState<boolean>(true)
    const [surveyInsight, setSurveyInsight] =
        React.useState<SurveyAnswerInsight>()
    const prepChecks = useAppSelector((state) => state.content.prepChecks)
    const buildInsightGridData = useBuildInsightGridData()

    const href = useMemo(() => {
        const pieces = location.pathname.split('/')
        return pieces[pieces.length - 1] + location.search
    }, [location.pathname, location.search])

    const hasMounted = useRef(false)

    const getQuestionHref = React.useCallback(
        (direction: 'next' | 'prev', currentHref: string): string => {
            const questionID = [
                'q1-answer-phase-accuracy',
                'q2-item-inventory',
                'q3-contact-usage',
            ]
            const buildQuestionHref = (newQuestionID: string): string => {
                const pieces = location.pathname.split('/')
                pieces[pieces.length - 1] = newQuestionID
                return pieces.join('/') + location.search
            }
            const currentID = questionID.find((id) => currentHref.includes(id))
            if (!currentID) return currentHref
            const currentIndex = questionID.indexOf(currentID)
            const newIndex =
                direction === 'next'
                    ? (currentIndex + 1) % questionID.length
                    : (currentIndex - 1 + questionID.length) % questionID.length
            return buildQuestionHref(questionID[newIndex])
        },
        [location.pathname, location.search]
    )

    const setSurvey = React.useCallback((insight: Insight) => {
        if (insight && insight.type === 'survey-answer') {
            setSurveyInsight(insight)
        }
        setRetrievingInsights(false)
    }, [])

    const refreshInsights = React.useCallback(
        async (
            existingQuery: InsightQueryDTO,
            reset = false,
            updatedParams = false
        ) => {
            setRetrievingInsights(true)
            getSingleInsight(
                InsightQueryType.PREP_CHECK_QUESTION,
                undefined,
                existingQuery.id > 0 || updatedParams
                    ? existingQuery.start_date
                    : undefined,
                existingQuery.id > 0 || updatedParams
                    ? existingQuery.end_date
                    : undefined,
                existingQuery.hazards,
                existingQuery.prep_checks,
                existingQuery.zips,
                reset,
                href
            )
                .then((res) => {
                    const { insights, quick_stat_row_title, ...newQuery } = res
                    persistLocalPickerChangesOnNewConfig(
                        newQuery.grid_config,
                        existingQuery.grid_config
                    )
                    setActiveQuery(newQuery)
                    setInsightData(insights)
                    setSurvey(insights[0])
                })
                .catch(console.error)
                .finally(() => setRetrievingInsights(false))
        },
        [href, setSurvey]
    )

    const fetchData = React.useCallback(() => {
        setRetrievingInsights(true)
        getSingleInsight(
            InsightQueryType.PREP_CHECK_QUESTION,
            searchParams,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            href,
            undefined,
            true
        )
            .then((res) => {
                const { insights, ...query } = res
                setInsightData(insights)
                setActiveQuery(query)
                setSurveyInsight(
                    insights[0].type === 'survey-answer'
                        ? insights[0]
                        : undefined
                )
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [searchParams, href])

    React.useEffect(() => {
        if (!hasMounted.current) {
            fetchData()
            hasMounted.current = true
        }
    }, [fetchData])

    React.useEffect(() => {
        getHazardData()
        getPrepCheckData()
    }, [organization])

    React.useEffect(() => {
        if (!surveyInsight) return
        document.title = `${surveyInsight.headline} - ResiliencePoint`
    }, [surveyInsight])

    const onSaveQuery = () => {
        saveQuery(
            InsightQueryType.PREP_CHECK_QUESTION,
            activeQuery?.start_date,
            activeQuery?.end_date,
            activeQuery?.hazards,
            activeQuery?.prep_checks,
            activeQuery?.zips,
            activeQuery?.grid_config
        )
    }

    const onNextQuestionPress = React.useCallback(() => {
        const nextHref = getQuestionHref('next', location.pathname)
        navigate(nextHref)
        fetchData()
    }, [getQuestionHref, location.pathname, navigate, fetchData])

    const onPrevQuestionPress = React.useCallback(() => {
        const prevHref = getQuestionHref('prev', location.pathname)
        navigate(prevHref)
        fetchData()
    }, [getQuestionHref, location.pathname, navigate, fetchData])

    const onRunQueryPress = React.useCallback(
        (values: QueryBuilderValues) => {
            if (!activeQuery || !user) return
            const active_query: InsightQueryDTO = {
                ...activeQuery,
                prep_checks: values.pickerOneValue
                    ? [parseInt(values.pickerOneValue.toString())]
                    : [],
                zips:
                    values.pickerTwoValue &&
                    values.pickerTwoValue !== 'jurisdiction'
                        ? [values.pickerTwoValue]
                        : [],
                start_date: values.start_date ?? undefined,
                end_date: values.end_date ?? undefined,
            }

            const newSearchParams = new URLSearchParams(location.search)
            if (values.pickerOneValue) {
                newSearchParams.set(
                    'prep_checks',
                    values.pickerOneValue.toString()
                )
            }
            if (values.pickerTwoValue) {
                newSearchParams.set('zips', values.pickerTwoValue.toString())
            }
            if (values.start_date) {
                newSearchParams.set(
                    'start_date',
                    values.start_date.toISOString()
                )
            }
            if (values.end_date) {
                newSearchParams.set('end_date', values.end_date.toISOString())
            }
            refreshInsights(active_query, undefined, true).then(() => {
                setActiveQuery(active_query)
                navigate(`${location.pathname}?${newSearchParams.toString()}`)
            })
        },
        [activeQuery, refreshInsights, user, navigate, location]
    )

    const refreshOneInsight = React.useCallback(
        async (
            placement_id: string,
            args: InsightQueryBase & RefreshInsightArgs
        ) => {
            args.grid_config = {
                ...args.grid_config,
                [placement_id]: {
                    ...args.grid_config[placement_id],
                    bbox: args.bbox,
                    zoom: args.zoom,
                },
            }
            delete args.bbox
            delete args.zoom
            setActiveQuery((aq) =>
                aq
                    ? {
                          ...aq,
                          ...args,
                      }
                    : undefined
            )
            const newInsight = await refreshInsight(
                placement_id,
                InsightQueryType.PREP_CHECKS,
                args
            )
            const currInsights = [...(insightData ?? [])]
            const index = currInsights.findIndex(
                (i) => i.placement_id === placement_id
            )
            if (index < 0) currInsights.push(newInsight)
            else currInsights.splice(index, 1, newInsight)
            setInsightData(currInsights)
        },
        [insightData]
    )

    const insights = React.useMemo(
        () => buildInsightGridData(insightData, activeQuery, refreshOneInsight),
        [buildInsightGridData, insightData, activeQuery, refreshOneInsight]
    )

    const onReloadPress = React.useCallback(() => {
        if (!activeQuery) return
        refreshInsights(activeQuery, undefined, true)
    }, [activeQuery, refreshInsights])

    const onResetQueryPress = React.useCallback(() => {
        setRetrievingInsights(true)
        getSingleInsight(
            InsightQueryType.PREP_CHECK_QUESTION,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            href,
            undefined,
            true
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [href])

    const prepCheckOptions = useMemo(
        () =>
            [...prepChecks]
                .sort((a, b) => (a.title < b.title ? -1 : 1))
                .map((pc) => ({ label: pc.title, value: pc.id })),
        [prepChecks]
    )

    const onBack = () => {
        const searchParams = new URLSearchParams(window.location.search)

        const prepChecks = searchParams.get('prep_checks')
        const startDate = searchParams.get('start_date')
        const endDate = searchParams.get('end_date')
        const zips = searchParams.get('zips')
        const newUrl = `/insights/prep-checks?prep_checks=${prepChecks}&zips=${zips}&start_date=${startDate}&end_date=${endDate}`
        navigate(newUrl)
    }

    return organization && user ? (
        <SurveyAnswerInsightTemplate
            organizationName={organization.name}
            prepCheckAreasForQueryBuilder={buildZipCodePickerItems(
                organization,
                false
            )}
            queryItems={prepCheckOptions}
            selectedPrepCheck={activeQuery?.prep_checks?.[0]}
            selectedArea={activeQuery?.zips?.[0] ?? 'jurisdiction'}
            startDate={
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : null
            }
            queryId={activeQuery?.id}
            endDate={
                activeQuery?.end_date ? new Date(activeQuery.end_date) : null
            }
            onReloadPress={onReloadPress}
            updatedDate={
                activeQuery?.date_last_used
                    ? new Date(activeQuery.date_last_used)
                    : undefined
            }
            onRunQueryPress={onRunQueryPress}
            onResetQueryPress={onResetQueryPress}
            onSaveQueryPress={onSaveQuery}
            onDownloadPress={() =>
                exportInsights(
                    InsightQueryType.PREP_CHECKS,
                    organization.slug,
                    activeQuery
                )
            }
            onNextQuestionPress={onNextQuestionPress}
            onPrevQuestionPress={onPrevQuestionPress}
            onBack={onBack}
            surveyData={surveyInsight}
            selectedLocationString={
                activeQuery?.zips?.join(', ') || 'Full Jurisdiction'
            }
            selectedDatesString={formatDateRange(
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : undefined,
                activeQuery?.end_date
                    ? new Date(activeQuery.end_date)
                    : undefined
            )}
            minQueryDate={new Date(organization.date_created)}
            insights={insights}
            loading={loading || retrievingInsights}
        />
    ) : null
}
