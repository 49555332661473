import {
    AddOn,
    GetRPOrganizationsResponse,
    GetSubscriptionsDTO,
    InsightQueryHistory,
    InsightQueryType,
    Organization,
    OrganizationChangelogDTO,
    OrganizationInviteDTO,
    OrganizationOverview,
    OrganizationWithMembers,
    RPUser,
    UpdateOrganizationDTO,
    colors,
} from '@hazadapt-git/public-core-base'
import { AxiosError, AxiosResponse } from 'axios'
import { isa } from '../api'
import { toast } from './misc'
import { NewRPUser, PickerItem } from '../entities'
import { store } from '../store'
import { setOrganization } from '../slices/base'
import { setAddons, setSubscriptions } from '../slices/payment'
import { getEnvVars } from '../config'
import {
    IoAlertCircle,
    IoCheckmark,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import { errorColor, primaryIconSize, successColor } from '../styles/universal'

const { apiUrl } = getEnvVars()

export const getSplashOrgDetails = async (): Promise<OrganizationOverview> => {
    const response: AxiosResponse<Organization> = await isa.get('/splash')
    return response.data
}

export const getOrganization = async (): Promise<Organization> => {
    const response: AxiosResponse<Organization> = await isa.get(
        '/organization?overview=true'
    )
    return response.data
}

export const getFullOrganization =
    async (): Promise<OrganizationWithMembers> => {
        const response: AxiosResponse<OrganizationWithMembers> = await isa.get(
            '/organization'
        )
        return response.data
    }

export const getOrganizations =
    async (): Promise<GetRPOrganizationsResponse> => {
        const response: AxiosResponse<GetRPOrganizationsResponse> =
            await isa.get('/organizations')
        return response.data
    }

export const updateOrganization = async (
    org: OrganizationWithMembers
): Promise<OrganizationWithMembers> => {
    const { members, ...organization } = org
    const response: AxiosResponse<OrganizationWithMembers> = await isa.put<
        any,
        AxiosResponse<OrganizationWithMembers>,
        UpdateOrganizationDTO
    >('/organization', { organization, members })
    store.dispatch(setOrganization({ ...response.data }))
    return response.data
}

/**
 * Uploads the user's profile picture to S3
 * @param image the profile picture file
 * @returns a signed URL for the profile picture
 */
export const updateOrganizationLogo = async (
    image: File
): Promise<OrganizationWithMembers> => {
    try {
        toast(
            'Saving...',
            <IoCloudUploadOutline
                color={colors.primary.BLUEBERRY}
                size={primaryIconSize}
            />
        )
        const formData = new FormData()
        formData.append('logo', image)
        const response: AxiosResponse<OrganizationWithMembers> = await isa.put(
            '/organization',
            formData,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            }
        )
        store.dispatch(setOrganization({ ...response.data }))
        toast(
            'Logo updated!',
            <IoCheckmark size={primaryIconSize} color={successColor} />
        )
        return response.data
    } catch (err) {
        toast(
            'There was an error uploading your logo. Please try again.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
        throw err
    }
}

export const inviteUser = async (newUser: NewRPUser[]): Promise<RPUser[]> => {
    const response: AxiosResponse<RPUser[]> = await isa.post('/member', newUser)
    return response.data
}

export const removeUser = async (userIds: number[]): Promise<RPUser[]> => {
    const response: AxiosResponse<RPUser[]> = await isa.delete(
        `/member?user_id=${userIds.join(',')}`
    )
    return response.data
}

export const getInvite = async (
    inviteCode: string
): Promise<OrganizationInviteDTO> => {
    const response: AxiosResponse<OrganizationInviteDTO> = await isa.get(
        `/invite/${inviteCode}`
    )
    return response.data
}

export const getSavedQueries = async (
    page = 1,
    queryType?: InsightQueryType
): Promise<InsightQueryHistory> => {
    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/queries`)
    url.searchParams.append('page', page.toString())
    if (queryType) url.searchParams.append('query_type', queryType)
    const response: AxiosResponse<InsightQueryHistory> = await isa.get(
        url.toString()
    )
    return response.data
}

export const getOrganizationChangelog = async (
    page = 1
): Promise<OrganizationChangelogDTO> => {
    const response: AxiosResponse<OrganizationChangelogDTO> = await isa.get(
        `/changelog/organization?page=${page}`
    )
    return response.data
}

export const getAvailableAddons = async (): Promise<void> => {
    try {
        const response: AxiosResponse<AddOn[]> = await isa.get('/add-ons')
        store.dispatch(setAddons(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const getSubscriptions = async (): Promise<void> => {
    try {
        const response: AxiosResponse<GetSubscriptionsDTO> = await isa.get(
            '/subscription'
        )
        store.dispatch(setSubscriptions(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const cancelSubscription = async (
    subscription_id: string
): Promise<void> => {
    try {
        const response: AxiosResponse<GetSubscriptionsDTO> = await isa.post(
            `/subscription/${subscription_id}/cancel`
        )
        store.dispatch(setSubscriptions(response.data))
    } catch (err) {
        const error = err as AxiosError
        throw error
    }
}

export const deletePaymentMethod = async (
    payment_method_id: string
): Promise<void> => {
    try {
        const response: AxiosResponse<GetSubscriptionsDTO> = await isa.delete(
            `/payment-method/${payment_method_id}`
        )
        store.dispatch(setSubscriptions(response.data))
    } catch (err) {
        console.error(err)
    }
}

export const buildZipCodePickerItems = (
    organization?: Organization | null,
    inline = false
): PickerItem<string>[] => {
    if (!organization) return []
    const zips = [...organization.areas.zip]
    zips.sort()
    const options: PickerItem<string>[] = [
        {
            label: inline ? 'your jurisdiction' : 'Full Jurisdiction',
            value: 'jurisdiction',
        },
        ...zips.map((z) => ({
            label: `Zip code ${z}`,
            value: z,
        })),
    ]
    return options
}

export const sortOrgMembers = (a: RPUser, b: RPUser): number => {
    if (a.status === 'invited') return 1
    if (b.status === 'invited') return -1
    if (a.owner) return -1
    if (b.owner) return 1
    if (a.admin && !b.admin) return -1
    if (b.admin && !a.admin) return 1
    if (a.date_created && b.date_created) {
        return a.date_created < b.date_created ? -1 : 1
    } else {
        return a.last_name < b.last_name ? -1 : 1
    }
}
