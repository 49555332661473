import React, { FC, useEffect, useMemo, useState } from 'react'
import {
    Link,
    TableCell,
    TableRow,
    Typography,
    Avatar,
    Collapse,
    Stack,
    IconButton,
} from '@mui/material'
import { ExportFormat, colors } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import { primaryIconSize } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'

interface InsightQueryViewProps {
    id: number
    name: string
    // email: string
    profilePictureURI?: string
    description: string
    area: string
    dateRange: string
    timestamp: string
    openQueryHref: string
    onDownloadReportPress(id: number, format?: ExportFormat): void
}

export const InsightQueryView: FC<InsightQueryViewProps> = ({
    id,
    name,
    profilePictureURI,
    description,
    area,
    dateRange,
    timestamp,
    openQueryHref,
    onDownloadReportPress,
}: InsightQueryViewProps) => {
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')

    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        setExpanded(false)
    }, [lgScreens])

    const InsightQueryActions = useMemo(
        () => (
            <div className={localClasses.actionContainer}>
                <Link href={openQueryHref} className={localClasses.link}>
                    Open Query
                </Link>
                <Link
                    component="button"
                    variant="body1"
                    onClick={() => onDownloadReportPress(id)}
                    className={localClasses.link}
                >
                    Download Report
                </Link>
            </div>
        ),
        [localClasses, openQueryHref, onDownloadReportPress, id]
    )

    const Chevron = expanded ? IoChevronUp : IoChevronDown

    return (
        <>
            <TableRow
                key={`query-${id}`}
                sx={lgScreens ? null : { '& > *': { borderBottom: 'unset' } }}
            >
                {/* User */}
                <TableCell>
                    <div className={localClasses.rowContainer}>
                        <Avatar
                            sx={{
                                height: '2.5rem',
                                width: '2.5rem',
                                bgcolor: profilePictureURI
                                    ? undefined
                                    : colors.grays.CUMULUS,
                            }}
                            src={profilePictureURI}
                        >
                            {profilePictureURI || name.length === 0
                                ? null
                                : name[0]}
                        </Avatar>
                        <Typography>{name}</Typography>
                    </div>
                </TableCell>

                {/* Query Description */}
                <TableCell>
                    <Typography fontWeight={500}>{description}</Typography>
                    <Typography>
                        {area}
                        {dateRange.length > 0 ? ` | ${dateRange}` : ''}
                    </Typography>
                </TableCell>

                {/* Timestamp */}
                {lgScreens && (
                    <TableCell>
                        {timestamp.split('\n').map((chunk, idx) => (
                            <Typography key={`timestamp-chunk-${idx + 1}`}>
                                {chunk}
                            </Typography>
                        ))}
                    </TableCell>
                )}

                {/* Actions */}
                <TableCell>
                    {lgScreens ? (
                        InsightQueryActions
                    ) : (
                        <IconButton onClick={() => setExpanded((ex) => !ex)}>
                            <Chevron
                                size={primaryIconSize}
                                color={colors.primary.CERULEAN}
                            />
                        </IconButton>
                    )}
                </TableCell>
                {/* // TODO: Implement format selection menu */}
            </TableRow>
            {!lgScreens && (
                <TableRow>
                    <TableCell sx={{ py: 0 }} colSpan={12}>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Stack my="1rem" gap="1rem" alignItems="flex-start">
                                <Typography>
                                    <Typography
                                        fontWeight={500}
                                        component="span"
                                    >
                                        Date Last Used:{' '}
                                    </Typography>
                                    {timestamp.split('\n').join(', ')}
                                </Typography>
                                {InsightQueryActions}
                            </Stack>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

const useLocalStyles = makeStyles()({
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
    cta: {
        whiteSpace: 'nowrap',
    },
    link: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
        width: 'fit-content',
        textAlign: 'left',
    },
})
