import { colors } from '@hazadapt-git/public-core-base'
import {
    Box,
    Button,
    Divider,
    Grid,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import { PaymentElement } from '@stripe/react-stripe-js'
import React, { FC, useCallback } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import Stripe from 'stripe'
import { makeStyles } from 'tss-react/mui'
import { primaryIconSize, theme } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { format } from 'date-fns-tz'
import { RPPaymentMethod } from '../../lib/entities'
import { PaymentMethodDetails } from '../molecules'
import classNames from 'classnames'

interface CheckoutPageTemplateProps {
    submitDisabled?: boolean
    onSubmit: React.FormEventHandler
    subscription?: Stripe.Subscription
    goBack: React.MouseEventHandler
    showPaymentElement: boolean
    setShowPaymentElement: React.Dispatch<React.SetStateAction<boolean>>
    paymentMethods: RPPaymentMethod[]
    selectedPaymentMethod: Stripe.PaymentMethod | null
    onSelectPaymentMethod(id: string): void
}

export const CheckoutPageTemplate: FC<CheckoutPageTemplateProps> = ({
    submitDisabled,
    onSubmit,
    subscription,
    goBack,
    showPaymentElement,
    setShowPaymentElement,
    paymentMethods,
    selectedPaymentMethod,
    onSelectPaymentMethod,
}) => {
    const { classes: localClasses } = useLocalStyles({
        numPaymentMethods: paymentMethods.length,
    })()
    const mdScreens = useWindowSizeUp('md')

    const handleUseExistingPaymentMethod: React.MouseEventHandler = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowPaymentElement(false)
        },
        [setShowPaymentElement]
    )

    const handleAddNewPaymentMethod: React.MouseEventHandler = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowPaymentElement(true)
        },
        [setShowPaymentElement]
    )

    const lgScreens = useWindowSizeUp('lg')
    return subscription ? (
        <Box
            p="1rem"
            width={{ xs: '100%', sm: '90vw' }}
            maxWidth="80rem"
            display="grid"
            margin="0 auto"
            gridTemplateColumns={{ xs: '1fr', lg: '2fr 1fr', xl: '3fr 1fr' }}
            gap="1rem"
            height="fit-content"
        >
            <Box gridColumn={{ lg: '1 / span 2' }} my="1rem">
                <Link
                    component="button"
                    onClick={goBack}
                    variant="h4"
                    underline="none"
                >
                    <IoArrowBack size={primaryIconSize} />
                    Back
                </Link>
                <Typography variant="h1" my="1rem">
                    Checkout
                </Typography>
            </Box>
            <Box order={{ xs: 3, lg: 2 }} pr={{ xs: 0, lg: '2rem' }}>
                <form onSubmit={onSubmit} className={localClasses.paymentForm}>
                    <Typography variant="h2">Payment Details</Typography>
                    {showPaymentElement || !selectedPaymentMethod ? (
                        <PaymentElement options={{ layout: 'accordion' }} />
                    ) : (
                        <Stack gap="1rem">
                            <Typography variant="h4" component="p">
                                Your Payment Methods
                            </Typography>
                            <div className={localClasses.paymentMethods}>
                                {paymentMethods.map((pm) => (
                                    <div
                                        className={classNames(
                                            localClasses.paymentMethod,
                                            {
                                                [localClasses.selectedPaymentMethod]:
                                                    selectedPaymentMethod.id ===
                                                    pm.id,
                                            }
                                        )}
                                        onClick={() =>
                                            onSelectPaymentMethod(pm.id)
                                        }
                                    >
                                        <PaymentMethodDetails {...pm} />
                                    </div>
                                ))}
                            </div>
                        </Stack>
                    )}
                    <div className={localClasses.checkoutCtas}>
                        <Button
                            variant="text"
                            onClick={
                                showPaymentElement
                                    ? handleUseExistingPaymentMethod
                                    : handleAddNewPaymentMethod
                            }
                            size="large"
                            className={localClasses.checkoutCta}
                        >
                            {showPaymentElement ? 'Use Existing' : 'Add New'}{' '}
                            Payment Method
                        </Button>
                        {!mdScreens && <Divider sx={{ mt: 0 }}>OR</Divider>}
                        <Button
                            variant="contained"
                            disabled={submitDisabled}
                            type="submit"
                            fullWidth={!mdScreens}
                            size="large"
                            className={localClasses.checkoutCta}
                        >
                            Complete Purchase
                        </Button>
                    </div>
                </form>
            </Box>
            <Box
                order={{ xs: 2, lg: 3 }}
                display="flex"
                flexDirection="column"
                gap="1rem"
                borderLeft={
                    lgScreens ? `1px ${colors.grays.NIMBUS} solid` : undefined
                }
                pl={lgScreens ? '2rem !important' : undefined}
            >
                <Typography variant="h2">Order Details</Typography>
                {subscription.items.data.map((item) =>
                    typeof item.price.product === 'string' ||
                    !('name' in item.price.product) ||
                    !item.quantity ||
                    !item.price.unit_amount ? null : (
                        <Grid container spacing={2} key={item.id} pb="1rem">
                            <Grid item xs={9}>
                                <Typography>
                                    {item.quantity} x {item.price.product.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} textAlign="right">
                                <Typography>
                                    $
                                    {(
                                        ((item.price.unit_amount ?? 0) *
                                            item.quantity) /
                                        100
                                    ).toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2">
                                    ${(item.price.unit_amount / 100).toFixed(2)}{' '}
                                    each
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                )}
                <Typography fontWeight={500}>
                    Renewing on{' '}
                    {format(
                        subscription.current_period_end * 1000,
                        'MMMM d, y'
                    )}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Typography
                            fontWeight={500}
                            color={colors.primary.WAIKATO}
                        >
                            Subtotal
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            fontWeight={500}
                            color={colors.primary.WAIKATO}
                            textAlign="right"
                        >
                            $
                            {subscription.items.data
                                .reduce(
                                    (prev: number, curr) =>
                                        (prev +=
                                            ((curr.quantity ?? 0) *
                                                (curr.price.unit_amount ?? 0)) /
                                            100),
                                    0
                                )
                                .toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                {!lgScreens && <Divider sx={{ margin: 0, width: '100%' }} />}
            </Box>
        </Box>
    ) : null // TODO: Implement message directing user back home
}

const useLocalStyles = ({ numPaymentMethods }: { numPaymentMethods: number }) =>
    makeStyles()({
        paymentForm: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
        },
        checkoutCtas: {
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignSelf: 'flex-end',
                width: 'fit-content',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
            },
        },
        checkoutCta: {
            maxWidth: theme.breakpoints.values.sm,
        },
        paymentMethods: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            [theme.breakpoints.up('sm')]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
            [theme.breakpoints.up('lg')]: {
                gridTemplateColumns: `repeat(${Math.min(
                    Math.max(numPaymentMethods, 2),
                    3
                )}, 1fr)`,
            },
            [theme.breakpoints.up('xl')]: {
                gridTemplateColumns: `repeat(${Math.min(
                    Math.max(numPaymentMethods, 2),
                    4
                )}, 1fr)`,
            },
            gap: '1rem',
        },
        paymentMethod: {
            padding: '1rem 2rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: `1px ${colors.grays.CUMULUS} solid`,
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
        },
        selectedPaymentMethod: {
            border: `2px ${colors.primary.CERULEAN} solid`,
        },
        existingPaymentMethodDetails: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    })
