import React, { FC } from 'react'

import { LoadingView, SetupProfilePageTemplate } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { useAppDispatch } from '../lib/store'
import { RPUser } from '@hazadapt-git/public-core-base'
import { updateUser } from '../lib/slices/base'
import { useNavigate } from 'react-router'
import {
    getAvatars,
    importProfilePictureFromHazAdaptID,
    saveUserChanges,
    toast,
} from '../lib/utils'
import { IoAlertCircle } from 'react-icons/io5'
import { errorColor, primaryIconSize } from '../lib/styles/universal'

interface SetupProfilePageProps extends NamespacedPageProps {}

export const SetupProfilePage: FC<SetupProfilePageProps> = ({
    user,
    organization,
}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [avatars, setAvatars] = React.useState<string[]>([])

    const [pendingChanges, setPendingChanges] = React.useState<Partial<RPUser>>(
        {}
    )

    React.useEffect(() => {
        const fetchAvatars = async () => {
            try {
                const avatarsData = await getAvatars()
                setAvatars(avatarsData)
            } catch (error) {
                console.error('Error fetching avatars:', error)
            }
        }

        fetchAvatars()
    }, [])

    React.useEffect(() => {
        document.title = 'Setup Profile - ResiliencePoint'
    }, [])

    const onUserUpdate = (changes: Partial<RPUser>) => {
        setPendingChanges((prevChanges) => ({
            ...prevChanges,
            ...changes,
        }))
    }

    const onContinue = async () => {
        if (!user) return
        if (
            pendingChanges.first_name?.trim() === '' ||
            pendingChanges.last_name?.trim() === ''
        ) {
            toast(
                'First and Last Name are required.',
                <IoAlertCircle size={primaryIconSize} color={errorColor} />
            )
            return
        }

        try {
            await saveUserChanges({
                ...user,
                ...pendingChanges,
                status: 'active',
            })
            dispatch(
                updateUser({
                    ...pendingChanges,
                    status: 'active',
                })
            )
            setPendingChanges({})
            if (user.owner && organization?.status === 'invited') {
                navigate('/setup/organization')
            } else {
                navigate('/home')
            }
        } catch (err) {
            console.error(err)
        }
    }

    return user?.hazadapt_id_email && organization ? (
        <SetupProfilePageTemplate
            header={'First, complete your profile.'}
            entityName={organization.name}
            firstName={pendingChanges.first_name ?? user.first_name}
            lastName={pendingChanges.last_name ?? user.last_name}
            title={pendingChanges.title ?? user.title ?? ''}
            email={pendingChanges.email ?? user.email}
            hazadapt_id_email={user.hazadapt_id_email}
            profilePicture={
                (pendingChanges.profile_picture_uri ||
                    user.profile_picture_uri) ??
                ''
            }
            disableEmail // TODO: Refine for more conditional usage as needed
            onFirstNameChange={(first_name) => onUserUpdate({ first_name })}
            onLastNameChange={(last_name) => onUserUpdate({ last_name })}
            onTitleChange={(title) => onUserUpdate({ title })}
            onEmailChange={(email) => onUserUpdate({ email })}
            onImportClick={importProfilePictureFromHazAdaptID}
            onChangeProfilePicture={(profile_picture_uri) =>
                onUserUpdate({ profile_picture_uri })
            }
            avatarOptions={avatars}
            onContinue={onContinue}
        />
    ) : (
        <LoadingView />
    )
}
