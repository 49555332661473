import { debounce } from 'debounce'
import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import {
    MapInsight,
    colors,
    InsightConfig,
} from '@hazadapt-git/public-core-base'
import {
    Layer,
    Map,
    MapRef,
    NavigationControl,
    Source,
    ViewState,
    ViewStateChangeEvent,
} from 'react-map-gl'
import mapboxgl, { MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl'
import Color from 'color'
import { makeStyles } from 'tss-react/mui'
import { Markdown } from '../atoms'
import {
    Button,
    CircularProgress,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    IoCheckmark,
    IoContract,
    IoEllipsisVertical,
    IoExpand,
    IoEyedrop,
} from 'react-icons/io5'
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson'
import classNames from 'classnames'
import { Box } from '@mui/system'
import { useWindowSizeUp } from '../../lib/utils'
import { prepCheckColor, theme } from '../../lib/styles/universal'
import tinycolor from 'tinycolor2'
import { MapThemePickerModal } from './MapThemePickerModal'
import { RootState, useAppSelector } from '../../lib/store'
import bboxPolygon from '@turf/bbox'

export interface MapInsightViewProps extends Omit<MapInsight, 'headline'> {
    headline: ReactNode
    defaultZoom?: number
    defaultCenter?: number[]
    onUpdate(
        placement_id: string,
        bbox: BBox2d,
        zoom: number
    ): void | Promise<void>
    className?: string
    config: InsightConfig
    onSwitchVariant: (variant: string) => void | Promise<void>
    onSwitchColorTheme: (theme: string) => void
}

export const MapInsightView: FC<MapInsightViewProps> = ({
    defaultZoom,
    defaultCenter,
    onSwitchColorTheme,
    onUpdate,
    className,
    config,
    onSwitchVariant,
    placement_id,
    ...data
}: MapInsightViewProps) => {
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const mapRef = useRef<MapRef>(null)

    // Create a popup, but don't add it to the map yet.
    const popup = useRef<{
        elem: mapboxgl.Popup
        feature_id: string | null
    }>({
        elem: new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        }),
        feature_id: null,
    })

    const { classes: localClasses } = useLocalStyles()
    const { variant, theme } = config.pickers

    const [viewState, setViewState] = useState<Partial<ViewState>>({
        longitude: defaultCenter?.[0],
        latitude: defaultCenter?.[1],
        zoom: defaultZoom,
    })
    const [themePickerOpen, setThemePickerOpen] = useState<boolean>(false)
    const [temporaryTheme, setTemporaryTheme] = useState<string>(
        theme?.selected_option ?? colors.secondary.GREENSHEEN
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [mapExpanded, setMapExpanded] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeVariant, setActiveVariant] = useState<string>(
        variant?.selected_option ?? ''
    )
    const lgScreens = useWindowSizeUp('lg')
    const { desktopMenuOpen } = useAppSelector((state: RootState) => state.base)
    const mobileMenuOpen = Boolean(anchorEl)

    useEffect(() => {
        const newViewState: Partial<ViewState> = {}
        if (defaultCenter) {
            newViewState.longitude = defaultCenter[0]
            newViewState.latitude = defaultCenter[1]
        }
        if (defaultZoom) newViewState.zoom = defaultZoom
        setViewState((vs) => ({
            ...vs,
            ...newViewState,
        }))
    }, [defaultCenter, defaultZoom])

    const handleMobileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setAnchorEl(null)
    }

    const handleVariantChange = useCallback(
        async (selectedVariant: string) => {
            if (selectedVariant === activeVariant) return

            setActiveVariant(selectedVariant)
            setLoading(true)
            try {
                await onSwitchVariant(selectedVariant)
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [activeVariant, onSwitchVariant]
    )

    const configurePopup = useCallback(
        (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            if (
                !e.features ||
                e.features.length === 0 ||
                !['Polygon', 'MultiPolygon'].includes(
                    e.features?.[0].geometry.type
                ) ||
                !e.features?.[0].properties?.label
            )
                return
            // Change the cursor style as a UI indicator.
            e.target.getCanvas().style.cursor = 'pointer'

            const rawContent = e.features[0].properties.label
            const content = document.createElement('div')
            content.innerHTML = renderToStaticMarkup(
                <Markdown content={rawContent} />
            )

            // Populate the popup and set its coordinates on the user's mouse location.
            popup.current.elem.remove()
            popup.current.elem
                .setLngLat(e.lngLat)
                .setDOMContent(content)
                .addTo(e.target)
            popup.current.feature_id = e.features[0].properties.id
        },
        []
    )

    const setPopupLatLng = useCallback((e: MapLayerMouseEvent) => {
        popup.current.elem.setLngLat(e.lngLat)

        if (
            !e.features ||
            e.features.length === 0 ||
            !['Polygon', 'MultiPolygon'].includes(
                e.features?.[0].geometry.type
            ) ||
            !e.features?.[0].properties?.label
        )
            return
        if (popup.current.feature_id === e.features?.[0].properties.id) return

        const rawContent = e.features[0].properties.label
        const content = document.createElement('div')
        content.innerHTML = renderToStaticMarkup(
            <Markdown content={rawContent} />
        )
        popup.current.elem.setDOMContent(content)
        popup.current.feature_id = e.features[0].properties.id
    }, [])

    const clearPopup = useCallback(
        (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            e.target.getCanvas().style.cursor = ''
            popup.current.elem.remove()
        },
        []
    )

    const updateMapContents = useCallback(
        async (map: mapboxgl.Map | MapRef) => {
            if (loading) return
            const bounds = map.getBounds()
            const sw = bounds.getSouthWest()
            const ne = bounds.getNorthEast()
            const bbox = [sw.lng, sw.lat, ne.lng, ne.lat] as BBox2d
            const zoom = map.getZoom()
            setLoading(true)
            popup.current.elem.remove()
            try {
                await onUpdate(placement_id, bbox, zoom)
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        },
        [onUpdate, placement_id, loading]
    )

    const handleMapMovement = useCallback(
        async (e: ViewStateChangeEvent) => {
            updateMapContents(e.target)
        },
        [updateMapContents]
    )

    const handleTileClick = useCallback(
        async (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
            if (!mapRef.current) return
            if (
                !e.features ||
                e.features.length === 0 ||
                !['Polygon', 'MultiPolygon'].includes(
                    e.features?.[0].geometry.type
                )
            )
                return
            const bbox = bboxPolygon(e.features[0])
            mapRef.current.fitBounds([
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]],
            ])
        },
        []
    )

    const updateViewState = useCallback((e: ViewStateChangeEvent) => {
        setViewState(e.viewState)
    }, [])

    const observeResize = useCallback(
        (
            container: HTMLDivElement,
            previousDimensions: { height: number; width: number }
        ) => {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    const newHeight = entry.contentRect.height
                    const newWidth = entry.contentRect.width

                    if (
                        newHeight > previousDimensions.height ||
                        newWidth > previousDimensions.width
                    ) {
                        previousDimensions.height = newHeight
                        previousDimensions.width = newWidth
                        if (mapRef.current) {
                            mapRef.current.resize()
                        }
                    }
                }
            })

            resizeObserver.observe(container)

            return () => {
                resizeObserver.unobserve(container)
            }
        },
        []
    )

    useEffect(() => {
        if (!mapContainerRef.current) return

        const mapContainer = mapContainerRef.current

        const cleanupChildObserver = observeResize(mapContainer, {
            height: mapContainer.clientHeight,
            width: mapContainer.clientWidth,
        })

        return cleanupChildObserver
    }, [observeResize])

    const expandOrCollapseMap = useCallback(() => {
        if (!mapContainerRef.current || !mapRef.current) return
        setMapExpanded((ex) => !ex)
        setTimeout(() => {
            if (!mapRef.current) return
            updateMapContents(mapRef.current)
        }, 400)
    }, [updateMapContents])

    const updateContentsIfWidthChanged = useMemo(
        () =>
            debounce(() => {
                if (mapRef.current) updateMapContents(mapRef.current)
            }, 250),
        [updateMapContents]
    )

    useEffect(() => {
        window.addEventListener('resize', updateContentsIfWidthChanged)
        return () => {
            window.removeEventListener('resize', updateContentsIfWidthChanged)
        }
    }, [updateContentsIfWidthChanged])

    useEffect(() => {
        if (!mapRef.current) return
        mapRef.current.resize()
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 300)
    }, [desktopMenuOpen])

    return (
        <>
            {/* MAP HEADER */}
            <div
                className={localClasses.header}
                style={{
                    backgroundColor: placement_id.includes('prep-check')
                        ? prepCheckColor
                        : '#4088B4',
                }}
            >
                <Typography className={localClasses.headerText}>
                    {data.headline}
                </Typography>
                <Tooltip
                    title={`${mapExpanded ? 'Shrink' : 'Expand'} map`}
                    placement="top"
                    arrow
                >
                    <IconButton
                        onClick={expandOrCollapseMap}
                        sx={{
                            color: colors.grays.BLANC,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {mapExpanded ? <IoContract /> : <IoExpand />}
                    </IconButton>
                </Tooltip>
            </div>

            <div
                className={classNames(localClasses.mapContainer, className, {
                    [localClasses.expandedMapContainer]: mapExpanded,
                })}
                ref={mapContainerRef}
            >
                {/* MAP CONTROLS */}
                <div className={localClasses.mapControls}>
                    {theme && theme.options.length > 0 && (
                        <Box
                            className={localClasses.iconContainer}
                            sx={{ borderBottomRightRadius: '0.5rem' }}
                        >
                            <IconButton
                                onClick={() => setThemePickerOpen(true)}
                            >
                                <IoEyedrop
                                    color={colors.grays.NOIR}
                                    size="1rem"
                                />
                            </IconButton>
                        </Box>
                    )}
                    {variant?.options.length > 0 && (
                        <>
                            {lgScreens ? (
                                <div className={localClasses.variantContainer}>
                                    {variant.options.map((o) => {
                                        const backgroundColor =
                                            o.buttonColor ??
                                            colors.secondary.BONDI
                                        const borderColor =
                                            o.value === activeVariant
                                                ? Color(
                                                      o.buttonColor ??
                                                          colors.secondary.BONDI
                                                  )
                                                      .lighten(0.9)
                                                      .toString()
                                                : o.buttonColor ??
                                                  colors.secondary.BONDI
                                        const hoverBackgroundColor = Color(
                                            o.buttonColor ??
                                                colors.secondary.BONDI
                                        )
                                            .darken(0.1)
                                            .toString()
                                        const hoverBorderColor =
                                            o.value === activeVariant
                                                ? borderColor
                                                : hoverBackgroundColor
                                        return (
                                            <Button
                                                key={o.value}
                                                onClick={() =>
                                                    handleVariantChange(o.value)
                                                }
                                                className={
                                                    localClasses.variantOption
                                                }
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor:
                                                            hoverBackgroundColor,
                                                        borderColor:
                                                            hoverBorderColor,
                                                    },
                                                    backgroundColor:
                                                        backgroundColor,
                                                    borderColor: borderColor,
                                                }}
                                            >
                                                {o.label}
                                            </Button>
                                        )
                                    })}
                                </div>
                            ) : (
                                <Box
                                    className={localClasses.iconContainer}
                                    sx={{
                                        borderBottomLeftRadius: '0.5rem',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <IconButton onClick={handleMobileMenuOpen}>
                                        <IoEllipsisVertical
                                            color={colors.grays.NOIR}
                                            size="1rem"
                                        />
                                    </IconButton>
                                    <Menu
                                        id="map-insight-menu"
                                        anchorEl={anchorEl}
                                        open={mobileMenuOpen}
                                        onClose={handleMobileMenuClose}
                                    >
                                        {variant.options.map((o) => (
                                            <MenuItem
                                                key={o.value}
                                                onClick={() =>
                                                    handleVariantChange(o.value)
                                                }
                                            >
                                                {o.value === activeVariant ? (
                                                    <>
                                                        <ListItemIcon>
                                                            <IoCheckmark />
                                                        </ListItemIcon>
                                                        <span>{o.label}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <ListItemText inset>
                                                            {o.label}
                                                        </ListItemText>
                                                    </>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            )}
                        </>
                    )}
                </div>

                {/* MAP */}
                <Map
                    {...viewState}
                    ref={mapRef}
                    mapLib={import('mapbox-gl')}
                    projection={{ name: 'mercator' }}
                    mapStyle="mapbox://styles/mapbox/light-v11"
                    style={{ height: '100%' }}
                    onLoad={(e) => {
                        e.target.resize()
                    }}
                    onMove={updateViewState}
                    interactiveLayerIds={data.map_config.features
                        .map((tile) => [
                            `${tile.properties.id}--line`,
                            `${tile.properties.id}--fill`,
                        ])
                        .flat()}
                    onClick={handleTileClick}
                    onDragEnd={handleMapMovement}
                    onZoomEnd={handleMapMovement}
                    reuseMaps
                    onMouseEnter={configurePopup}
                    onMouseMove={setPopupLatLng}
                    onMouseLeave={clearPopup}
                    onTouchEnd={configurePopup}
                    onTouchCancel={clearPopup}
                >
                    <NavigationControl
                        style={{
                            marginTop:
                                lgScreens ||
                                !variant ||
                                variant.options.length === 0
                                    ? '0.5625rem'
                                    : '3.75rem',
                        }}
                    />
                    {data.map_config.features.map((tile) => (
                        <Source
                            id={tile.properties.id}
                            type="geojson"
                            data={tile}
                            key={tile.properties.id}
                        >
                            <Layer
                                id={`${tile.properties.id}--fill`}
                                type="fill"
                                source={tile.properties.id}
                                paint={{
                                    'fill-color':
                                        tile.properties.value > 0
                                            ? tinycolor(temporaryTheme)
                                                  .lighten(
                                                      tile.properties.lightening
                                                  )
                                                  .toRgbString()
                                            : tinycolor(colors.grays.CUMULUS)
                                                  .lighten(25)
                                                  .toRgbString(),
                                    'fill-opacity': 0.67,
                                }}
                            />
                            <Layer
                                id={`${tile.properties.id}--line`}
                                type="line"
                                source={tile.properties.id}
                                paint={{
                                    'line-color': colors.grays.NIMBUS,
                                    'line-width': 1,
                                }}
                            />
                        </Source>
                    ))}
                </Map>

                {/* LOADING OVERLAY */}
                {loading ? (
                    <div className={localClasses.loadingContainer}>
                        <div className={localClasses.loading}>
                            <CircularProgress size="1rem" color="primary" />
                            <Typography fontWeight={500}>Loading...</Typography>
                        </div>
                    </div>
                ) : null}
            </div>
            <MapThemePickerModal
                open={themePickerOpen}
                onClose={() => setThemePickerOpen(false)}
                themeConfig={theme}
                selectedTheme={temporaryTheme}
                setTheme={setTemporaryTheme}
                onSaveThemeChange={() => onSwitchColorTheme(temporaryTheme)}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '4rem',
        padding: '0 1rem',
    },
    headerText: {
        alignItems: 'center',
        color: colors.grays.BLANC,
        display: 'flex',
        flexWrap: 'wrap',
        fontWeight: 500,
        whiteSpace: 'break-spaces',
    },
    mapContainer: {
        height: '15rem',
        position: 'relative',
        transition: 'all .3s ease-in-out',
        [theme.breakpoints.up('md')]: {
            height: '20rem',
        },
        [theme.breakpoints.up('xl')]: {
            height: '25rem',
        },
    },
    expandedMapContainer: {
        height: '30rem',
        [theme.breakpoints.up('md')]: {
            height: '40rem',
        },
        [theme.breakpoints.up('xl')]: {
            height: '50rem',
        },
    },
    map: {
        height: '100%',
    },
    mapControls: {
        alignItems: 'flex-start',
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    iconContainer: {
        backgroundColor: colors.grays.BLANC,
        boxShadow: '0 0.25rem 0.5rem rgba(0,0,0,0.16)',
        padding: '.5rem',
        pointerEvents: 'all',
        top: 0,
    },
    variantContainer: {
        display: 'flex',
        gap: '.5rem',
        marginLeft: 'auto',
        marginRight: '2.625rem',
        padding: '.5rem',
    },
    variantOption: {
        borderRadius: '.5rem',
        borderStyle: 'solid',
        borderWidth: 3,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        minWidth: '10rem',
        padding: '.5rem',
        pointerEvents: 'all',
    },
    loadingContainer: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 2,
    },
    loading: {
        alignItems: 'center',
        backgroundColor: colors.grays.BLANC,
        borderRadius: '0.5rem',
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        padding: '1rem',
    },
})
