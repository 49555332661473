import { Avatar, Link, Typography } from '@mui/material'
import React, { FC } from 'react'
import { theme } from '../../lib/styles/universal'
import { makeStyles } from 'tss-react/mui'
import HazShield from '../../assets/images/haz-shield.svg'

interface ProfileOverviewProps {
    profile_picture_uri?: string
    first_name: string
    last_name: string
    title: string
    isAdmin?: boolean
    onChangeProfilePictureClick: React.MouseEventHandler
}

export const ProfileOverview: FC<ProfileOverviewProps> = (props) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div className={localClasses.container}>
            <Avatar
                src={props.profile_picture_uri ?? undefined}
                sx={{
                    width: {
                        xs: '5rem',
                        md: '7rem',
                    },
                    height: {
                        xs: '5rem',
                        md: '7rem',
                    },
                }}
            />
            <div className={localClasses.textContainer}>
                <Typography variant="h4">
                    {props.first_name} {props.last_name}
                </Typography>
                <Typography className={localClasses.userTitle}>
                    {props.isAdmin && (
                        <img src={HazShield} alt="HazAdapt admin shield" />
                    )}
                    {props.title}
                </Typography>
            </div>
            <Link
                component="button"
                onClick={props.onChangeProfilePictureClick}
            >
                Change Profile Picture
            </Link>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'grid',
        gridTemplateColumns: '5rem 1fr',
        alignItems: 'center',
        gap: '0.5rem',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '7rem 1fr',
        },
    },
    avatarContainer: {
        width: 'min-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    textContainer: {
        paddingLeft: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        gap: '0.5rem',
    },
    userTitle: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    },
})
