import { colors } from '@hazadapt-git/public-core-base'
import { Input, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'

interface FileUploadProps {
    onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FileUpload: FC<FileUploadProps> = (props: FileUploadProps) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div>
            <Typography variant="h4" pb="0.625rem">
                File Name
            </Typography>
            <div className={localClasses.uploadBox}>
                <Input
                    type="file"
                    onChange={props.onFileSelect}
                    disableUnderline={true}
                    fullWidth={true}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    uploadBox: {
        padding: '0.5rem',
        border: `1px solid ${colors.grays.NOIR}`,
        borderRadius: '0.5rem',
    },
})
