import {
    NewRPUserDTO,
    OrganizationWithMembers,
    RPUser,
    colors,
    RPUserPermission,
} from '@hazadapt-git/public-core-base'
import { Button, Grid, Typography } from '@mui/material'
import { ChangeEventHandler, FC, useRef, useState } from 'react'
import { IoMap, IoPencil, IoRefresh } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'
import { TeamMemberList } from '../organisms/TeamMemberList'
import { AddPhotoAlternate } from '@mui/icons-material'
import {
    AddTeamMemberModal,
    ConfirmOrganizationLogoModal,
    ConfirmSkipSetupModal,
    ViewJurisdictionModal,
} from '../organisms'
import { acceptedImageTypes } from '../../lib/entities'
import { theme } from '../../lib/styles/universal'
import {
    useWindowSizeUp,
    toBase64,
    checkUserPermissions,
} from '../../lib/utils'
import { ReactCropperElement } from 'react-cropper'

interface BaseTemplateProps {
    activeUser: RPUser
    organization: OrganizationWithMembers
    onSaveLogoClick(src: File): void
    onSubmitNewUser(user: Partial<RPUser>): void
    onResendInvite?(user: RPUser): void
    onRemoveUser(user: RPUser): void
    onSaveUserChanges(user: RPUser): void
    pendingChanges?: boolean
}

interface SetupOrganizationPageTemplateProps {
    mode: 'setup'
    onSaveLogoClick(src: File): void
    onDoneClick: React.MouseEventHandler
    onConfirmSkip?(): void
}

interface ManageOrganizationPageTemplateProps {
    mode: 'manage'
    onGoToOrgChangelog?(): void
}

type OrganizationDetailsTemplateProps = BaseTemplateProps &
    (SetupOrganizationPageTemplateProps | ManageOrganizationPageTemplateProps)

const DEFAULT_NEW_USER: NewRPUserDTO = {
    first_name: '',
    last_name: '',
    email: '',
    permissions: [],
}

export const OrganizationDetailsTemplate: FC<
    OrganizationDetailsTemplateProps
> = (props: OrganizationDetailsTemplateProps) => {
    const { classes: localClasses } = useLocalStyles()
    const [jurisdictionOpen, setJurisdictionOpen] = useState<boolean>(false)
    const [logoDialogOpen, setLogoDialogOpen] = useState<boolean>(false)
    const [logoCropperImage, setLogoCropperImage] = useState('')
    const [newUser, setNewUser] = useState<NewRPUserDTO>(DEFAULT_NEW_USER)
    const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false)
    const [confirmSkipOpen, setConfirmSkipOpen] = useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const cropperRef = useRef<ReactCropperElement>(null)
    const mdScreens = useWindowSizeUp('md')

    const onAddMemberClose = () => {
        setAddMemberOpen(false)
        setNewUser(DEFAULT_NEW_USER)
    }

    const onConfirmSkip = () => {
        if (props.mode === 'manage') return
        setConfirmSkipOpen(false)
        props.onConfirmSkip?.()
    }

    const submitNewUser = (newUser: NewRPUserDTO) => {
        props.onSubmitNewUser(newUser)
        onAddMemberClose()
    }

    const handleFileInputChange: ChangeEventHandler<HTMLInputElement> = async (
        e
    ) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        const fileStr = await toBase64(file)
        if (!fileStr) return
        setLogoCropperImage(fileStr)
        setLogoDialogOpen(true)
    }

    const handleInputButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const onSaveLogoClick = () => {
        cropperRef.current?.cropper
            ?.getCroppedCanvas({ fillColor: 'white' })
            .toBlob((blob) => {
                if (!blob) return
                const file = new File([blob], `${props.organization.name}`, {
                    type: 'image/jpeg',
                })
                props.onSaveLogoClick(file)
            }, 'image/jpeg')
        const data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
        setLogoCropperImage(data || logoCropperImage)
        setLogoDialogOpen(false)
    }

    const onCancelClick = () => {
        setLogoDialogOpen(false)
        setLogoCropperImage(props.organization.logo?.url || '')
    }

    const updateNewUser = (changes: Partial<NewRPUserDTO>) => {
        setNewUser((user) => ({
            ...user,
            ...changes,
        }))
    }

    return (
        <Grid container spacing={2} className={localClasses.container}>
            <Grid item xs={12} className={localClasses.organization}>
                <Typography
                    variant={mdScreens ? 'h2' : 'h3'}
                    component="h1"
                    color={colors.grays.NOIR}
                >
                    {props.mode === 'manage'
                        ? 'Organization Settings'
                        : `Welcome to ResiliencePoint! 🎉`}
                </Typography>
                {props.mode === 'setup' && (
                    <Typography>
                        You can finish setting up your organization now, or you
                        can press Skip to get straight into tracking your
                        community's resilience!
                    </Typography>
                )}
            </Grid>
            {props.mode === 'manage' || props.organization.logo ? (
                <Grid item xs="auto">
                    <div className={localClasses.logoContainer}>
                        <img
                            src={
                                logoCropperImage || props.organization.logo?.url
                            }
                            alt={props.organization.logo?.alt}
                            className={localClasses.logo}
                        />
                        <Button
                            variant="contained"
                            onClick={handleInputButtonClick}
                            className={localClasses.editButton}
                        >
                            <IoPencil
                                className={localClasses.pencil}
                                style={{
                                    color: `${colors.grays.BLANC}`,
                                }}
                            />
                        </Button>
                    </div>
                </Grid>
            ) : (
                <Grid item alignSelf="center">
                    <Button
                        variant="outlined"
                        className={localClasses.topButton}
                        onClick={handleInputButtonClick}
                    >
                        <AddPhotoAlternate
                            className={localClasses.icon}
                            style={{
                                color: `${colors.grays.THUNDERCLOUD}`,
                            }}
                        />
                        <span className={localClasses.span}>
                            Add Organization Logo*
                        </span>
                    </Button>
                </Grid>
            )}
            <Grid
                item
                alignSelf="center"
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems="stretch"
                gap="0.5rem"
                md
                width={{ xs: '100%', md: 'fit-content' }}
            >
                <Button
                    variant="outlined"
                    className={localClasses.topButton}
                    onClick={() => setJurisdictionOpen(true)}
                >
                    <IoMap size="2rem" color={colors.grays.THUNDERCLOUD} />
                    <span className={localClasses.span}>
                        View Jurisdiction Map
                    </span>
                </Button>
                {props.mode === 'manage' &&
                checkUserPermissions(props.activeUser, [
                    RPUserPermission.EDIT_ORGANIZATION,
                ]) ? (
                    <Button
                        variant="outlined"
                        className={localClasses.topButton}
                        onClick={props.onGoToOrgChangelog}
                    >
                        <IoRefresh
                            size="2rem"
                            color={colors.grays.THUNDERCLOUD}
                        />
                        <span className={localClasses.span}>
                            View Change History
                        </span>
                    </Button>
                ) : null}
                <input
                    type="file"
                    accept={acceptedImageTypes}
                    ref={fileInputRef}
                    hidden
                    onChange={handleFileInputChange}
                />
                <ConfirmOrganizationLogoModal
                    open={logoDialogOpen}
                    title="Confirm Logo"
                    selectedImage={logoCropperImage}
                    onImageChange={setLogoCropperImage}
                    onCancelClick={onCancelClick}
                    onSaveClick={onSaveLogoClick}
                    onClose={onCancelClick}
                    ref={cropperRef}
                />
                <ViewJurisdictionModal
                    open={jurisdictionOpen}
                    onClose={() => setJurisdictionOpen(false)}
                    jurisdiction={props.organization.jurisdiction}
                />
            </Grid>
            <Grid item xs={12}>
                <TeamMemberList
                    members={props.organization.members}
                    seats={props.organization.seats}
                    onAddUserClick={() => setAddMemberOpen(true)}
                    mode={props.mode}
                    onResendInvite={props.onResendInvite}
                    onRemoveUser={props.onRemoveUser}
                    activeUser={props.activeUser}
                    onOpenConfirmSkipSetupModal={() => setConfirmSkipOpen(true)}
                    onSaveUserChanges={props.onSaveUserChanges}
                />
                <AddTeamMemberModal
                    activeUser={props.activeUser}
                    open={addMemberOpen}
                    onClose={onAddMemberClose}
                    onSubmit={submitNewUser}
                    newUser={newUser}
                    updateNewUser={updateNewUser}
                />
            </Grid>
            <Grid item xs={12} className={localClasses.ctas}>
                {props.mode === 'setup' ? (
                    <>
                        <Button
                            variant="outlined"
                            onClick={() => setConfirmSkipOpen(true)}
                        >
                            Skip
                        </Button>
                        <ConfirmSkipSetupModal
                            open={confirmSkipOpen}
                            onClose={() => setConfirmSkipOpen(false)}
                            onConfirmSkip={onConfirmSkip}
                            pendingChanges={props.pendingChanges}
                            onSaveChanges={props.onDoneClick}
                        />
                        <Button variant="contained" onClick={props.onDoneClick}>
                            Continue
                        </Button>
                    </>
                ) : null}
            </Grid>
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    organization: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        flexGrow: 1,
    },
    pencil: {
        height: '1rem',
        width: '1rem',
        zIndex: 2,
    },
    ctas: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1.5rem',
        justifyContent: 'flex-end',
        padding: '1rem',
    },
    span: {
        color: colors.grays.NOIR,
        fontSize: '1rem',
    },
    icon: {
        height: '3rem',
        width: '3rem',
    },
    topButton: {
        gap: '1rem',
        flex: 1,
        width: '100%',
        padding: '1rem',
        [theme.breakpoints.up('md')]: {
            height: '5rem',
            maxWidth: '20rem',
            whiteSpace: 'nowrap',
        },
        borderRadius: '1rem',
        borderColor: colors.grays.THUNDERCLOUD,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: { alignItems: 'center' },
        margin: '0 auto',
        width: '100%',
    },
    sub: {
        gap: '2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    topButtons: {
        gap: '2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    editButton: {
        position: 'absolute',
        height: '2rem',
        width: '2rem',
        boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1)',
        padding: 0,
        minWidth: '2rem',
        right: '1rem',
        bottom: '1.5rem',
        zIndex: 1,
    },
    logo: {
        width: 'auto',
        minWidth: '8rem',
        height: '8rem',
        objectFit: 'contain',
        [theme.breakpoints.up('md')]: {
            height: '10rem',
            minWidth: '10rem',
        },
        [theme.breakpoints.up('lg')]: {
            height: '12rem',
        },
    },
    logoContainer: {
        position: 'relative',
        width: 'fit-content',
    },
})
