import {
    Avatar,
    Badge,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { primaryIconSize } from '../../lib/styles/universal'
import React, { FC } from 'react'
import { GroupViewType } from '../../lib/entities'
import {
    IoEllipsisVertical,
    IoSend,
    IoTrash,
    IoPencil,
    IoArrowRedo,
    IoMail,
} from 'react-icons/io5'
import { RPUser, colors } from '@hazadapt-git/public-core-base'
import { format } from 'date-fns-tz'
import { TeamMemberConfigModal, TeamMemberDeleteModal } from '../organisms'
import {
    canUserAddMembers,
    canUserDeleteMembers,
    canUserEditMembers,
    canUserManageMembers,
    isUserAdmin,
} from '../../lib/utils'
import classNames from 'classnames'

export interface TeamMemberActionsProps {
    manager: RPUser
    user: RPUser
    onDeleteClick(): void
    onEditClick(mode: 'email' | 'permissions'): void
    onResendInvite?(user: RPUser): void
    inline?: boolean
    open: boolean
    anchorEl: HTMLElement | null
    setAnchorEl(el: HTMLElement | null): void
}

export const TeamMemberActions: FC<TeamMemberActionsProps> = ({
    manager,
    user,
    onDeleteClick,
    onEditClick,
    onResendInvite,
    inline,
    open,
    anchorEl,
    setAnchorEl,
}) => {
    const { classes: localClasses } = useLocalStyles()

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const onOptionsClose = React.useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const handleResend = React.useCallback(() => {
        onResendInvite?.(user)
    }, [onResendInvite, user])

    const handleEditEmail = React.useCallback(() => {
        onEditClick('email')
        setAnchorEl(null)
    }, [onEditClick, setAnchorEl])

    const handleEditPermissions = React.useCallback(() => {
        onEditClick('permissions')
        setAnchorEl(null)
    }, [onEditClick, setAnchorEl])

    const handleDelete = React.useCallback(() => {
        onDeleteClick()
        setAnchorEl(null)
    }, [onDeleteClick, setAnchorEl])

    return (
        <div className={classNames({ [localClasses.menuButton]: !inline })}>
            <IconButton onClick={onOptionsClick}>
                <IoEllipsisVertical
                    size={primaryIconSize}
                    color={colors.grays.NOIR}
                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={onOptionsClose}
                MenuListProps={{
                    'aria-labelledby': 'options-button',
                }}
            >
                {onResendInvite &&
                user.status === 'invited' &&
                (canUserAddMembers(manager) || canUserEditMembers(manager)) ? (
                    <MenuItem onClick={handleResend}>
                        <ListItemIcon>
                            <IoArrowRedo size={primaryIconSize} />
                        </ListItemIcon>
                        <ListItemText>Resend Invitation</ListItemText>
                    </MenuItem>
                ) : null}
                {canUserEditMembers(manager) ? (
                    <MenuItem onClick={handleEditPermissions}>
                        <ListItemIcon>
                            <IoPencil size={primaryIconSize} />
                        </ListItemIcon>
                        <ListItemText>Edit Permissions</ListItemText>
                    </MenuItem>
                ) : null}
                {isUserAdmin(manager) ? (
                    <MenuItem onClick={handleEditEmail}>
                        <ListItemIcon>
                            <IoMail size={primaryIconSize} />
                        </ListItemIcon>
                        <ListItemText>Change Email Address</ListItemText>
                    </MenuItem>
                ) : null}
                {/* TODO: Pause Access functionality */}
                {/* <MenuItem>
                    <ListItemIcon>
                        <IoPause size={primaryIconSize} />
                    </ListItemIcon>
                    <ListItemText>Pause Access</ListItemText>
                </MenuItem> */}
                {canUserDeleteMembers(manager) ? (
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <IoTrash
                                size={primaryIconSize}
                                color={colors.primary.CORAL}
                            />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography
                                color={colors.primary.CORAL}
                                fontWeight={500}
                            >
                                Remove from Organization
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                ) : null}
            </Menu>
        </div>
    )
}
export interface TeamMemberProps {
    user: RPUser
    groupView: GroupViewType
    activeUser: RPUser
    onResendInvite?(user: RPUser): void
    onSaveChangesClick(user: RPUser): void
    onRemoveUser(user: RPUser): void
}

export const TeamMemberView: FC<TeamMemberProps> = ({
    user,
    groupView,
    activeUser,
    onResendInvite,
    onSaveChangesClick,
    onRemoveUser,
}) => {
    const [configModalOpen, setConfigModalOpen] = React.useState<boolean>(false)
    const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false)
    const [configModalMode, setConfigModalMode] = React.useState<
        'email' | 'permissions'
    >('email')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const { classes: localClasses } = useLocalStyles()

    const onEditClick = (mode: 'email' | 'permissions') => {
        setConfigModalMode(mode)
        setConfigModalOpen(true)
    }

    const handleCloseMemberConfigModal = () => {
        setConfigModalOpen(false)
        setConfigModalMode('email')
    }

    return (
        <>
            {groupView === 'card' ? (
                // card view
                <div className={localClasses.card}>
                    <Box
                        className={classNames(
                            localClasses.box,
                            user.status === 'invited'
                                ? localClasses.invitedUser
                                : undefined
                        )}
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={
                                user.status === 'invited' ? (
                                    <IoSend
                                        size="1.25rem"
                                        color={colors.grays.BLANC}
                                        className={localClasses.invitedBadge}
                                    />
                                ) : null
                            }
                            sx={{
                                margin: '0.5rem',
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: {
                                        xs: '3.5rem',
                                        md: '4.75rem',
                                        lg: '6.75rem',
                                    },
                                    height: {
                                        xs: '3.5rem',
                                        md: '4.75rem',
                                        lg: '6.75rem',
                                    },
                                }}
                                src={user.profile_picture_uri ?? undefined}
                            />
                        </Badge>
                        <Typography
                            variant="h4"
                            fontWeight={500}
                            textAlign="center"
                            sx={{
                                wordWrap: 'break-word',
                            }}
                        >
                            {`${user.first_name} ${user.last_name}`}
                        </Typography>
                        <Typography fontWeight={400} textAlign="center">
                            {user.status === 'invited' ? 'Invited' : user.title}
                        </Typography>
                        {user.owner ? (
                            <Typography
                                fontWeight={400}
                                textAlign="center"
                                lineHeight="2.5rem"
                            >
                                Owner
                            </Typography>
                        ) : canUserManageMembers(activeUser) ? (
                            <TeamMemberActions
                                manager={activeUser}
                                user={user}
                                onResendInvite={onResendInvite}
                                onDeleteClick={() => setDeleteModalOpen(true)}
                                onEditClick={onEditClick}
                                open={open}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            />
                        ) : null}
                    </Box>
                </div>
            ) : (
                // list view
                <TableRow
                    sx={{ '& > *': { borderBottom: 'unset', width: '25%' } }}
                >
                    <TableCell>
                        <Typography textAlign="left" fontWeight={500}>
                            {`${user.first_name} ${user.last_name}`}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            fontStyle={
                                user.status === 'invited' ? 'italic' : 'normal'
                            }
                        >
                            {user.status === 'invited' ? 'Invited' : user.title}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography fontStyle={'italic'}>
                            {user.email}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <div className={localClasses.listViewDateAndActions}>
                            {user.date_created && (
                                <Typography>
                                    {format(
                                        new Date(user.date_created),
                                        'MMMM d, y'
                                    )}
                                </Typography>
                            )}
                            {canUserManageMembers(activeUser) ? (
                                <TeamMemberActions
                                    manager={activeUser}
                                    user={user}
                                    onDeleteClick={() =>
                                        setDeleteModalOpen(true)
                                    }
                                    onEditClick={() => setConfigModalOpen(true)}
                                    onResendInvite={onResendInvite}
                                    inline
                                    open={open}
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                />
                            ) : null}
                        </div>
                    </TableCell>
                </TableRow>
            )}
            <TeamMemberConfigModal
                user={user}
                onSaveChanges={(user) => {
                    onSaveChangesClick(user)
                    setConfigModalOpen(false)
                }}
                activeUser={activeUser}
                open={configModalOpen}
                onClose={handleCloseMemberConfigModal}
                mode={configModalMode}
            />
            <TeamMemberDeleteModal
                user={user}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onRemoveUser={onRemoveUser}
            />
        </>
    )
}

const useLocalStyles = makeStyles()({
    box: {
        width: '100%',
        display: 'grid',
        gridTemplateRows: '1fr',
        padding: '1rem',
        boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1)',
        borderRadius: '1rem',
        justifyItems: 'center',
        gap: '1rem',
    },
    invitedUser: {
        backgroundColor: colors.grays.WHITESMOKE,
    },
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        position: 'relative',
    },
    icon: {
        width: '2.75rem',
        height: '2.75rem',
        objectFit: 'contain',
    },
    list: {
        padding: '1rem 2rem',
        flexGrow: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        alignItems: 'center',
    },
    invitedBadge: {
        padding: '0.25rem',
        backgroundColor: colors.grays.THUNDERCLOUD,
        borderRadius: '25%',
    },
    menuButton: {
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
    },
    listViewDateAndActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
    },
})
