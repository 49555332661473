import {
    Insight,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import React, { FC, useMemo } from 'react'

import { PrepCheckInsightsTemplate, QueryBuilderValues } from '../components'
import { NamespacedPageProps, RefreshInsightArgs } from '../lib/entities'
import {
    useBuildInsightGridData,
    getInsights,
    refreshInsight,
    saveQuery,
    exportInsights,
    persistLocalPickerChangesOnNewConfig,
} from '../lib/utils/insights'
import { useAppSelector } from '../lib/store'
import { getHazardData, getPrepCheckData } from '../lib/utils/cms'
import { buildZipCodePickerItems, formatDateRange } from '../lib/utils'
import { useSearchParams } from 'react-router-dom'

interface PrepCheckInsightsProps extends NamespacedPageProps {}

export const PrepCheckInsights: FC<PrepCheckInsightsProps> = ({
    user,
    organization,
    loading,
}) => {
    const [searchParams] = useSearchParams()
    const [insightData, setInsightData] = React.useState<Insight[]>()
    const [quickStatRowTitle, setQuickStatRowTitle] = React.useState<string>()
    const [activeQuery, setActiveQuery] = React.useState<InsightQueryDTO>()
    const [retrievingInsights, setRetrievingInsights] =
        React.useState<boolean>(true)
    const prepChecks = useAppSelector((state) => state.content.prepChecks)
    const buildInsightGridData = useBuildInsightGridData()

    const refreshInsights = React.useCallback(
        async (
            existingQuery: InsightQueryDTO,
            reset = false,
            updatedParams = false
        ) => {
            setRetrievingInsights(true)
            getInsights(
                InsightQueryType.PREP_CHECKS,
                undefined,
                existingQuery.id > 0 || updatedParams
                    ? existingQuery.start_date
                    : undefined,
                existingQuery.id > 0 || updatedParams
                    ? existingQuery.end_date
                    : undefined,
                existingQuery.hazards,
                existingQuery.prep_checks,
                existingQuery.zips,
                reset
            )
                .then((res) => {
                    const { insights, quick_stat_row_title, ...newQuery } = res
                    persistLocalPickerChangesOnNewConfig(
                        newQuery.grid_config,
                        existingQuery.grid_config
                    )
                    setActiveQuery(newQuery)
                    setInsightData(insights)
                    setQuickStatRowTitle(quick_stat_row_title)
                })
                .catch(console.error)
                .finally(() => setRetrievingInsights(false))
        },
        []
    )

    React.useEffect(() => {
        getInsights(
            InsightQueryType.PREP_CHECKS,
            searchParams,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [searchParams])

    React.useEffect(() => {
        document.title = 'Prep Check Activity - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        getHazardData()
        getPrepCheckData()
    }, [organization])

    const onSaveQuery = () => {
        saveQuery(
            InsightQueryType.PREP_CHECKS,
            activeQuery?.start_date,
            activeQuery?.end_date,
            activeQuery?.hazards,
            activeQuery?.prep_checks,
            activeQuery?.zips,
            activeQuery?.grid_config,
            activeQuery?.insight_ids
        )
    }

    const onRunQueryPress = React.useCallback(
        (values: QueryBuilderValues) => {
            if (!activeQuery || !user) return
            const active_query: InsightQueryDTO = {
                ...activeQuery,
                prep_checks: values.pickerOneValue
                    ? [parseInt(values.pickerOneValue.toString())]
                    : [],
                zips:
                    values.pickerTwoValue &&
                    values.pickerTwoValue !== 'jurisdiction'
                        ? [values.pickerTwoValue]
                        : [],
                start_date: values.start_date ?? undefined,
                end_date: values.end_date ?? undefined,
            }
            refreshInsights(active_query, undefined, true)
        },
        [activeQuery, refreshInsights, user]
    )

    const buildSelectedQueryString = () => {
        const selectedPrepCheck = prepChecks.find(
            (pc) => pc.id === activeQuery?.prep_checks?.[0]
        )
        return selectedPrepCheck
            ? `${selectedPrepCheck.title} Prep Check`
            : 'All Prep Checks'
    }

    const refreshOneInsight = React.useCallback(
        async (
            placement_id: string,
            args: InsightQueryBase & RefreshInsightArgs
        ) => {
            args.grid_config = {
                ...args.grid_config,
                [placement_id]: {
                    ...args.grid_config[placement_id],
                    bbox: args.bbox,
                    zoom: args.zoom,
                },
            }
            delete args.bbox
            delete args.zoom
            setActiveQuery((aq) =>
                aq
                    ? {
                          ...aq,
                          ...args,
                      }
                    : undefined
            )
            const newInsight = await refreshInsight(
                placement_id,
                InsightQueryType.PREP_CHECKS,
                args
            )
            const currInsights = [...(insightData ?? [])]
            const index = currInsights.findIndex(
                (i) => i.placement_id === placement_id
            )
            if (index < 0) currInsights.push(newInsight)
            else currInsights.splice(index, 1, newInsight)
            setInsightData(currInsights)
        },
        [insightData]
    )

    const insights = React.useMemo(
        () => buildInsightGridData(insightData, activeQuery, refreshOneInsight),
        [buildInsightGridData, insightData, activeQuery, refreshOneInsight]
    )

    const onReloadPress = React.useCallback(() => {
        if (!activeQuery) return
        refreshInsights(activeQuery, undefined, true)
    }, [activeQuery, refreshInsights])

    const onResetQueryPress = React.useCallback(() => {
        setRetrievingInsights(true)
        getInsights(
            InsightQueryType.PREP_CHECKS,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            undefined,
            true
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [])

    const prepCheckOptions: PickerItem<number | undefined>[] = useMemo(
        () => [
            { label: 'Select Prep Check...', value: undefined },
            ...[...prepChecks]
                .sort((a, b) => (a.title < b.title ? -1 : 1))
                .map((pc) => ({ label: pc.title, value: pc.id })),
        ],
        [prepChecks]
    )

    return organization && user ? (
        <PrepCheckInsightsTemplate
            pageTitle="Prep Check Activity"
            organization={organization}
            queryItemTitle="Prep Check"
            queryItems={prepCheckOptions}
            selectedQueryItem={activeQuery?.prep_checks?.[0]}
            queryItemSelectorPlaceholder="Select a Prep Check..."
            communityAreasForQueryBuilder={buildZipCodePickerItems(
                organization,
                false
            )}
            selectedArea={activeQuery?.zips?.[0] ?? 'jurisdiction'}
            areaSelectorPlaceholder="Select an area..."
            queryId={activeQuery?.id}
            startDate={
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : null
            }
            endDate={
                activeQuery?.end_date ? new Date(activeQuery.end_date) : null
            }
            onReloadPress={onReloadPress}
            updatedDate={
                activeQuery?.date_last_used
                    ? new Date(activeQuery.date_last_used)
                    : undefined
            }
            onRunQueryPress={onRunQueryPress}
            onResetQueryPress={onResetQueryPress}
            onSaveQueryPress={onSaveQuery}
            onDownloadPress={() =>
                exportInsights(
                    InsightQueryType.PREP_CHECKS,
                    organization.slug,
                    activeQuery
                )
            }
            selectedQueryString={buildSelectedQueryString()}
            selectedLocationString={
                activeQuery?.zips?.join(', ') || 'Full Jurisdiction'
            }
            selectedDatesString={formatDateRange(
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : undefined,
                activeQuery?.end_date
                    ? new Date(activeQuery.end_date)
                    : undefined
            )}
            minQueryDate={new Date(organization.date_created)}
            insights={insights}
            quickStatRowTitle={quickStatRowTitle}
            loading={loading || retrievingInsights}
        />
    ) : null
}
