import { Button, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { FC } from 'react'

import MountainConquerer from '../../assets/images/MountainConquerer.svg'
import { Dialog } from '../atoms'

interface ConfirmSkipSetupModalProps {
    id?: string
    open?: boolean
    onClose(): void
    onSaveChanges: React.MouseEventHandler
    onConfirmSkip: React.MouseEventHandler
    pendingChanges?: boolean
}

export const ConfirmSkipSetupModal: FC<ConfirmSkipSetupModalProps> = (
    props: ConfirmSkipSetupModalProps
) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <Dialog
            {...props}
            id={props.id ?? 'confirm-skip-setup'}
            title="Organization setup is not complete!"
            compactTitle
            maxWidth="30rem"
            content={
                <div className={localClasses.content}>
                    <img
                        src={MountainConquerer}
                        alt="Mountain climber with a prosthetic leg holding up a yellow flag at the top of a mountain"
                        className={localClasses.image}
                    />
                    <Typography textAlign="center">
                        {props.pendingChanges
                            ? true &&
                              `It looks like you've got some unsaved changes. Your changes will be lost and invitations will not be sent if you skip organization setup.`
                            : `You're about to leave organization setup. You can always
                            finish setup later by clicking on Organization Settings
                            in the Settings menu.`}
                    </Typography>
                </div>
            }
            ctas={
                props.pendingChanges
                    ? [
                          <Button
                              variant="outlined"
                              color="secondary"
                              onClick={props.onClose}
                              key="cancel"
                          >
                              Cancel
                          </Button>,

                          <Button
                              variant="contained"
                              color="primary"
                              onClick={props.onConfirmSkip}
                              key="confirm-skip"
                          >
                              Confirm Skip
                          </Button>,
                      ]
                    : [
                          <Button
                              variant="outlined"
                              color="secondary"
                              onClick={props.onConfirmSkip}
                              key="confirm-skip"
                          >
                              Confirm Skip
                          </Button>,

                          <Button
                              variant="contained"
                              color="primary"
                              onClick={props.onSaveChanges}
                              key="save-changes"
                          >
                              Save Changes
                          </Button>,
                      ]
            }
        />
    )
}

const useLocalStyles = makeStyles()({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        maxWidth: '19rem',
        height: 'auto',
        maxHeight: '50vh',
        objectFit: 'contain',
        objectPosition: 'center',
        marginBottom: '1rem',
    },
})
