import React, { FC } from 'react'
import Select, {
    components,
    DropdownIndicatorProps,
    OnChangeValue,
    OptionProps,
    SingleValue,
    StylesConfig,
} from 'react-select'
import {
    CellMeasurer,
    CellMeasurerCache,
    List,
    ListRowRenderer,
} from 'react-virtualized'
import { PickerItem } from '../../lib/entities'
import { applyColorToPickerSubcomponents } from '../../lib/styles/universal'
import { colors } from '@hazadapt-git/public-core-base'
import { FormControl, Typography } from '@mui/material'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'

export interface PickerProps {
    data: PickerItem<any>[]
    selectColor?: string
    selectIcon?: React.ElementType
    fullWidth?: boolean
    inline?: boolean
    inputLabel?: string
    disabled?: boolean
    variableSize?: boolean
    bold?: boolean
    id: string
    dark?: boolean
    variant?: 'filled' | 'outlined'
    placeholder?: string
    showSearch?: boolean
    value?: any
    label?: string
    virtualized?: boolean
    onChange?: (value: any) => void
    smallLabel?: boolean
    detachedLabel?: boolean
}

type MenuListProps = {
    children: React.ReactNode
}

const MenuList: React.FC<MenuListProps> = ({ children }) => {
    const rows = React.Children.toArray(children)

    // cache for measuring row heights
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 34,
        minHeight: 34,
    })

    const rowRenderer: ListRowRenderer = ({
        key,
        index,
        style,
        parent,
    }: {
        key: string
        index: number
        style: React.CSSProperties
        parent: any
    }) => (
        // dynamically set row heights based on contents
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
        >
            {({ measure }) => (
                <div key={key} style={style} onLoad={measure}>
                    {rows[index]}
                </div>
            )}
        </CellMeasurer>
    )
    return (
        <List
            style={{ width: '100%' }}
            width={900}
            height={300}
            rowHeight={cache.rowHeight}
            rowCount={rows.length}
            rowRenderer={rowRenderer}
            deferredMeasurementCache={cache}
        />
    )
}

const DropdownIndicator = (props: DropdownIndicatorProps<PickerItem<any>>) => (
    <components.DropdownIndicator {...props}>
        {props.isFocused ? <IoChevronUp /> : <IoChevronDown />}
    </components.DropdownIndicator>
)

const CustomOption: FC<OptionProps<PickerItem<any>>> = (props) => {
    const { classes: localClasses } = useLocalStyles()
    const pickerItem = props.data as PickerItem<any>
    const hasImages = props.options.some(
        (option) => (option as PickerItem<any>).imageSrc
    )

    return (
        <components.Option {...props}>
            {pickerItem.imageSrc ? (
                <img
                    src={props.data.imageSrc}
                    alt={props.data.label}
                    className={localClasses.orgImage}
                />
            ) : (
                hasImages && <div className={localClasses.inset} />
            )}
            {props.children}
        </components.Option>
    )
}

export const Picker: FC<PickerProps> = (props: PickerProps) => {
    const {
        inputLabel,
        data,
        disabled,
        selectColor,
        selectIcon,
        fullWidth,
        inline,
        variableSize,
        bold,
        id,
        dark,
        value,
        variant = 'outlined',
        placeholder,
        showSearch,
        onChange,
        label,
        smallLabel,
        detachedLabel,
        virtualized = data.length > 20,
        ...selectProps
    } = props
    const { classes: localClasses } = useLocalStyles()

    const customStyles: StylesConfig<PickerItem<any>> = {
        container: () => ({
            border: 'none',
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: dark
                ? colors.grays.BLANC
                : provided.backgroundColor,
            borderRadius: '0.5rem',
            fontWeight: bold ? 500 : 'normal',
            height: '3rem',
            padding: `0 calc(0.5rem - ${state.isFocused ? 1 : 0}px)`,
            borderColor: state.isFocused
                ? colors.primary.CERULEAN
                : `rgba(0, 0, 0, 0.23)`, // To match MUI
            borderWidth: state.isFocused ? 2 : 1,
            boxShadow: 'none',
            boxSizing: 'border-box',
            transition: 'none',
            '&:hover': {
                borderColor: colors.primary.CERULEAN,
            },
        }),
        menu: (provided) => ({
            ...provided,
            color: dark ? colors.grays.BLANC : 'initial',
            borderRadius: '0.5rem',
            zIndex: 10,
            overflow: 'hidden',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: dark ? colors.grays.BLANC : provided.color,
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            overflow: 'auto',
        }),
        option: (provided, params) => ({
            ...provided,
            color: params.data.value ? colors.grays.NOIR : colors.grays.NIMBUS,
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 1300 }),
    }

    const handleChange = (
        selectedOption: OnChangeValue<PickerItem<any>, false>
    ) => {
        onChange?.(selectedOption?.value ?? null)
    }

    return (
        <FormControl
            fullWidth={fullWidth}
            variant={variant}
            disabled={disabled}
            sx={{
                ...(fullWidth
                    ? undefined
                    : {
                          width: variableSize ? 'fit-content' : '100%',
                          minWidth: 'unset',
                          maxWidth: variableSize ? undefined : '25rem',
                          height: 'auto',
                      }),
                ...applyColorToPickerSubcomponents(selectColor, variant),
                // ...props.sx,
            }}
        >
            {props.inputLabel && (
                <>
                    {props.detachedLabel ? (
                        <Typography
                            variant={props.smallLabel ? 'h5' : 'h4'}
                            pb="0.625rem"
                            color={props.selectColor}
                            component="label"
                            fontStyle="normal"
                            fontWeight={500}
                        >
                            {`${props.inputLabel}:`}
                        </Typography>
                    ) : (
                        <Typography
                            variant="body2"
                            component="label"
                            color={props.selectColor}
                            className={localClasses.nestedLabel}
                        >
                            {`${props.inputLabel}:`}
                        </Typography>
                    )}
                </>
            )}
            <Select
                {...selectProps}
                components={{
                    ...(virtualized ? { MenuList } : {}),
                    IndicatorSeparator: null,
                    DropdownIndicator,
                    Option: CustomOption,
                }}
                id={id}
                styles={customStyles}
                options={data}
                isDisabled={disabled}
                isSearchable
                placeholder={placeholder}
                isMulti={false}
                menuPortalTarget={document.body}
                onChange={(val) =>
                    handleChange(val as SingleValue<PickerItem<any>>)
                }
                value={data.find((option) => option.value === value) || null}
            />
        </FormControl>
    )
}

const useLocalStyles = makeStyles()({
    orgImage: {
        width: '1.25rem',
        height: '1.25rem',
        objectFit: 'contain',
        marginRight: '.5rem',
        verticalAlign: 'top',
    },
    inset: {
        display: 'inline-block',
        width: '1.25rem',
        height: '1.25rem',
        marginRight: '.5rem',
    },
    nestedLabel: {
        backgroundColor: '#fff',
        fontSize: '.75rem',
        fontWeight: '600',
        left: '.6rem',
        padding: '0 .25rem',
        position: 'absolute',
        top: '-.55rem',
        zIndex: 1,
    },
})
