import { FC } from 'react'
import { QuickStatInsightView, QuickStatInsightViewProps } from '../molecules'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

interface QuickStatInsightListProps {
    data: QuickStatInsightViewProps[]
    title?: string
}

export const QuickStatInsightList: FC<QuickStatInsightListProps> = ({
    title,
    data: insights,
}) => {
    const { classes: localClasses } = useLocalStyles()

    if (insights.length === 0) return null
    return (
        <div className={localClasses.container}>
            {title && (
                <Typography variant="h4" mb="0.5rem">
                    {title}
                </Typography>
            )}
            <div className={localClasses.list}>
                {insights.map((insight) => (
                    <QuickStatInsightView
                        {...insight}
                        key={insight.placement_id}
                    />
                ))}
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        width: '100%',
        overflowX: 'auto',
    },
    list: {
        display: 'flex',
        flexFlow: 'row',
        gap: '1rem',
        overflowX: 'auto',
        paddingBottom: '1rem',
    },
})
