import { Typography, IconButton, Skeleton, Stack } from '@mui/material'
import React from 'react'
import { IoReload } from 'react-icons/io5'
import { primaryIconSize, theme } from '../../lib/styles/universal'
import { InsightGrid } from './InsightGrid'
import {
    QueryBuilder,
    QueryBuilderSkeleton,
    QueryBuilderValues,
} from './QueryBuilder'
import { colors, Organization } from '@hazadapt-git/public-core-base'
import { InsightViewProps, LocalContentDataDisclaimer } from '../molecules'
import { PickerItem } from '../../lib/entities'
import { makeStyles } from 'tss-react/mui'
import { Popover } from '../atoms'
import { format } from 'date-fns-tz'
import { useWindowSizeUp } from '../../lib/utils'
import { InsightsLoadingOverlay } from './InsightsLoadingOverlay'

export interface InsightsPageProps {
    pageTitle: string
    organization: Organization
    showLocalContentDisclaimer?: boolean
    communityAreasForQueryBuilder: PickerItem<string>[]
    queryItemSelectorPlaceholder?: string
    selectedQueryItem?: number
    selectedArea?: string
    areaSelectorPlaceholder?: string
    queryId?: number
    queryItemTitle: string
    queryItems: PickerItem<number | undefined>[]
    startDate: Date | null
    endDate: Date | null
    onReloadPress: React.MouseEventHandler
    updatedDate?: Date
    onRunQueryPress(values: QueryBuilderValues): void
    onResetQueryPress: React.MouseEventHandler
    onSaveQueryPress: React.MouseEventHandler
    onDownloadPress: React.MouseEventHandler
    selectedQueryString: string
    selectedLocationString: string
    selectedDatesString: string
    insights?: InsightViewProps[]
    quickStatRowTitle?: string
    loading?: boolean
    minQueryDate: Date
}

export const InsightsPage = (props: InsightsPageProps) => {
    const { classes: localClasses } = useLocalStyles()
    const formattedDate = props.updatedDate
        ? format(props.updatedDate, 'M/d/y @ h:mm aaa')
        : undefined
    const mdScreens = useWindowSizeUp('md')

    React.useEffect(() => {
        if (props.loading) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [props.loading])

    return (
        <div className={localClasses.insightsPage}>
            {props.loading && <InsightsLoadingOverlay />}
            <div className={localClasses.header}>
                <div className={localClasses.titleAndQueryBuilder}>
                    <div className={localClasses.title}>
                        <Typography
                            variant={mdScreens ? 'h2' : 'h3'}
                            component="h1"
                            display="flex"
                            flexDirection="column"
                        >
                            {props.pageTitle}
                            <Typography
                                variant={mdScreens ? 'h3' : 'h4'}
                                component="span"
                            >
                                {props.organization.name}
                            </Typography>
                        </Typography>
                        {props.pageTitle === 'Prep Check Activity' ? (
                            <div style={{ transform: 'translateY(-.5rem)' }}>
                                <Popover
                                    popoverPlacement="right"
                                    helpText={`HazAdapt's Prep Checks are self-assessment that can help individuals measure three vital aspects of personal coping capacity for each hazard: knowledge, assets, and networks. Read more about the research behind Prep Checks and how we score coping capacity [here](https://www.hazadapt.com/our-products/about-prep-checks).`}
                                />
                            </div>
                        ) : null}
                    </div>
                    {(!props.queryId || props.queryId < 0) && formattedDate ? (
                        <div className={localClasses.lastUpdatedContainer}>
                            <IconButton onClick={props.onReloadPress}>
                                <IoReload
                                    color={colors.chips.PEROLOUS}
                                    size={primaryIconSize}
                                />
                            </IconButton>
                            <Typography variant="body1" fontWeight={500}>
                                Last Updated: {formattedDate}
                            </Typography>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                minWidth: '20rem',
                            }}
                        >
                            <Skeleton
                                variant="circular"
                                width={'3rem'}
                                height={'2.5rem'}
                            />
                            <Skeleton
                                variant="rounded"
                                width={'100%'}
                                sx={{ margin: 'auto 0 auto .25rem' }}
                            />
                        </div>
                    )}
                    <div className={localClasses.queryBuilderContainer}>
                        {props.loading ? (
                            <QueryBuilderSkeleton />
                        ) : (
                            <QueryBuilder
                                pickerOneLabel={props.queryItemTitle}
                                pickerOneValue={props.selectedQueryItem}
                                pickerOnePlaceholder={
                                    props.queryItemSelectorPlaceholder
                                }
                                pickerOneOptions={props.queryItems}
                                pickerTwoLabel="Area"
                                pickerTwoValue={props.selectedArea}
                                pickerTwoPlaceholder={
                                    props.areaSelectorPlaceholder
                                }
                                pickerTwoOptions={
                                    props.communityAreasForQueryBuilder
                                }
                                startDate={props.startDate}
                                endDate={props.endDate}
                                minDate={props.minQueryDate}
                                onRunQueryPress={props.onRunQueryPress}
                                onResetQueryPress={props.onResetQueryPress}
                                onSaveQueryPress={props.onSaveQueryPress}
                                onDownloadPress={props.onDownloadPress}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={localClasses.insightGridContainer}>
                {props.loading ? (
                    <Stack gap="0.5rem">
                        <Skeleton
                            variant="rounded"
                            width="min(80vw, 20rem)"
                            height={theme.typography.h3.lineHeight}
                        />
                        <Skeleton
                            variant="rounded"
                            width="min(80vw, 30rem)"
                            height={theme.typography.h4.lineHeight}
                        />
                    </Stack>
                ) : (
                    <div>
                        <Typography variant="h3" fontWeight={600}>
                            {props.selectedQueryString}
                        </Typography>
                        <Typography variant="h4">
                            {props.selectedLocationString} |{' '}
                            {props.selectedDatesString}
                        </Typography>
                    </div>
                )}
                {!props.loading && props.showLocalContentDisclaimer && (
                    <LocalContentDataDisclaimer />
                )}
                <InsightGrid
                    data={props.insights}
                    quickStatRowTitle={props.quickStatRowTitle}
                />
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    insightsPage: {
        position: 'relative',
    },
    header: {
        display: 'flex',
        padding: '1.25rem 0',
        gap: '1rem',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    titleAndQueryBuilder: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        flex: 1,
        padding: '0 1rem',
        gap: '0.5rem',
    },
    mdTitleAndQueryBuilder: {
        gridTemplateColumns: '1fr auto',
        gap: '1rem',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('lg')]: {
            gridColumn: 'unset',
        },
    },
    queryInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '1rem',
        gap: '1rem',
    },
    lastUpdatedContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gridColumn: '2',
        [theme.breakpoints.down('lg')]: {
            gridColumn: '1',
        },
    },
    queryBuilderContainer: {
        [theme.breakpoints.up('lg')]: { gridColumn: '1 / span 2' },
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1',
            justifySelf: 'center',
        },
    },
    insightGridContainer: {
        padding: '1rem 0',
        margin: '0 1rem',
        borderTop: `1px ${colors.grays.NOIR} solid`,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    queryCtas: {
        height: '3rem',
    },
})
