import { InsightPicker } from '@hazadapt-git/public-core-base'
import { FormControl, RadioGroup, Typography, Button } from '@mui/material'
import { FC } from 'react'
import { generateLinearGradientColors } from '../../lib/utils'
import { Dialog, RadioButton, LinearGradient } from '../atoms'
import { makeStyles } from 'tss-react/mui'

interface MapThemePickerModalProps {
    open: boolean
    onClose(): void
    themeConfig: InsightPicker
    setTheme(value: string): void
    selectedTheme: string
    onSaveThemeChange(): void
}

export const MapThemePickerModal: FC<MapThemePickerModalProps> = ({
    open,
    onClose,
    themeConfig,
    setTheme,
    selectedTheme,
    onSaveThemeChange,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const handleThemePickerClose = () => {
        const oldTheme = themeConfig.options.find(
            (option) => option.value === themeConfig.selected_option
        )
        if (oldTheme) setTheme(oldTheme.value)
        onClose()
    }

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTheme = themeConfig.options.find(
            (option) => option.label === e.target.value
        )
        if (newTheme) setTheme(newTheme.value)
    }

    const handleThemeChange = () => {
        if (themeConfig.selected_option !== selectedTheme) {
            onSaveThemeChange()
        }
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleThemePickerClose}
            title="Change Map Color Theme"
            width="24rem"
            content={
                <FormControl>
                    <RadioGroup
                        name="theme-options"
                        value={selectedTheme}
                        onChange={handleRadioChange}
                    >
                        {themeConfig?.options.map((o) => {
                            const gradientColors = generateLinearGradientColors(
                                o.value
                            )
                            const currentTheme = themeConfig.options.find(
                                (o) => o.value === selectedTheme
                            )
                            return (
                                <RadioButton
                                    label={
                                        <div
                                            className={localClasses.themeOption}
                                        >
                                            <Typography>{o.label}</Typography>
                                            <LinearGradient
                                                height="1rem"
                                                width="min(70vw, 10rem)"
                                                colors={gradientColors}
                                            />
                                        </div>
                                    }
                                    value={o.label}
                                    checked={o.label === currentTheme?.label}
                                    key={o.label}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            }
            ctas={[
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleThemePickerClose}
                    key="cancel"
                >
                    Cancel
                </Button>,
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleThemeChange}
                    key="update-theme"
                >
                    Update Theme
                </Button>,
            ]}
        />
    )
}

const useLocalStyles = makeStyles()({
    themeOption: {
        alignItems: 'center',
        display: 'grid',
        gap: '0.25rem',
        gridTemplateColumns: '4rem 1fr',
        overflow: 'hidden',
    },
})
