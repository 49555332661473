import React, { FC } from 'react'
import { MenuItemProps, RPPaymentMethod } from '../../lib/entities'
import { IconButton, Menu, Stack, Typography } from '@mui/material'
import { IoEllipsisVertical } from 'react-icons/io5'
import { primaryIconSize } from '../../lib/styles/universal'
import { CustomMenuItem } from '../../lib/entities/misc'
import { colors } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'

interface PaymentMethodDetailsProps extends RPPaymentMethod {
    actions?: MenuItemProps[]
}

export const PaymentMethodDetails: FC<PaymentMethodDetailsProps> = ({
    icon,
    type,
    last4,
    expirationDate,
    actions = [],
}) => {
    const { classes: localClasses } = useLocalStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={localClasses.container}>
            {icon}
            <Stack>
                {/* TODO: Default tag */}
                <Typography fontWeight={500}>
                    {type}
                    {last4 ? ` - Ending in ${last4}` : null}
                </Typography>
                {expirationDate && (
                    <Typography>Expires {expirationDate}</Typography>
                )}
            </Stack>
            {actions.length > 0 ? (
                <>
                    <IconButton
                        onClick={onOptionsClick}
                        className={localClasses.options}
                    >
                        <IoEllipsisVertical
                            size={primaryIconSize}
                            color={colors.grays.NOIR}
                        />
                    </IconButton>
                    <Menu
                        id="payment-method-options"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={onOptionsClose}
                    >
                        {actions.map((action) => (
                            <CustomMenuItem
                                {...action}
                                onClick={(e) => {
                                    action.onClick?.(e)
                                    onOptionsClose()
                                }}
                                key={action.label}
                            />
                        ))}
                    </Menu>
                </>
            ) : null}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    options: {
        alignSelf: 'flex-start',
        marginTop: '-0.5rem',
        position: 'absolute',
        top: '1rem',
        right: 0,
    },
})
