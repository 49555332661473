import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import RPZenDude from './../../assets/images/RP_zen_dude.svg'
import RPLogoBlack from './../../assets/images/RP_logo_black.svg'
import HazID from './../../assets/images/haz-id.svg'
import { Button } from '@mui/material'
import { useWindowSizeUp } from '../../lib/utils'
import { OrganizationOverview } from '@hazadapt-git/public-core-base'
import { theme } from '../../lib/styles/universal'

interface SplashPageTemplateProps {
    organization: OrganizationOverview
    signIn(): void
}

export const SplashPageTemplate: FC<SplashPageTemplateProps> = (
    props: SplashPageTemplateProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')

    return (
        <div className={localClasses.container}>
            <div className={localClasses.mainContent}>
                {mdScreens ? (
                    <img
                        src={RPZenDude}
                        alt="Emergency manager meditating above his desk"
                        style={{
                            width: '100%',
                            maxHeight: 'calc(100vh - 11.5rem)',
                        }}
                    />
                ) : null}
                <div className={localClasses.contents}>
                    <img
                        src={RPLogoBlack}
                        className={localClasses.rpLogo}
                        alt="ResiliencePoint logo"
                    />
                    <img
                        src={props.organization.logo?.url}
                        className={localClasses.orgLogo}
                        alt={`${props.organization.name} logo`}
                    />
                    <img
                        src={HazID}
                        className={localClasses.hazadaptID}
                        alt="Hazadapt ID"
                    />
                    <Button variant="contained" onClick={() => props.signIn()}>
                        Sign In With Your HazAdapt ID
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        background:
            'linear-gradient(180deg, rgba(0, 0, 87, 0.33) 0%, rgba(255, 255, 255, 0) 100%)',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '1rem',
    },
    mainContent: {
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '3fr 2fr',
        },
    },
    contents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: '1.5rem',
    },
    rpLogo: {
        width: 'min(60%, 20rem)',
        height: 'auto',
    },
    orgLogo: {
        height: '10rem',
        width: 'auto',
        maxWidth: 'calc(100% - 2rem)',
        objectFit: 'contain',
    },
    hazadaptID: {
        width: 'min(60%, 8rem)',
        height: 'auto',
    },
})
