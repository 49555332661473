import { Transaction, colors } from '@hazadapt-git/public-core-base'
import { Box, Typography, Grid, Button } from '@mui/material'
import React, { FC } from 'react'
import { IoInformationCircle } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import { Popover } from '../atoms'

interface CheckoutSuccessPageTemplateProps {
    email: string
    transaction: Transaction
    hideReturnToDashboard: boolean
}

export const CheckoutSuccessPageTemplate: FC<
    CheckoutSuccessPageTemplateProps
> = (props: CheckoutSuccessPageTemplateProps) => {
    const mdScreens = useWindowSizeUp('md')
    return (
        <Box maxWidth="47rem" mx="auto" px="1rem">
            <Typography
                variant={mdScreens ? 'h2' : 'h3'}
                component="h1"
                my="1.25rem"
            >
                Purchase Complete
            </Typography>
            <Box
                bgcolor={colors.softTones.WAIKATO}
                borderRadius="0.5rem"
                p="0.5rem"
                mb="1.25rem"
            >
                <Typography>
                    <strong>Receipt ID:</strong>{' '}
                    {props.transaction.transaction_id}
                </Typography>
                <Typography>
                    Confirmation email and receipt sent to{' '}
                    <strong>{props.email}</strong>.
                </Typography>
            </Box>
            <Grid container pb="1rem" borderBottom={2} marginBottom={2}>
                <Grid item xs={8}>
                    <Typography fontWeight={600}>Item</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography textAlign="center"> Amount </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography textAlign="center">Total</Typography>
                </Grid>
            </Grid>
            {props.transaction.products.map((item) => (
                <Grid container key={item.id} pb="1rem" alignItems="center">
                    <Grid item xs={8}>
                        <Typography display="flex" alignItems="center">
                            {item.name}
                            {item.description ? (
                                <Popover
                                    icon={
                                        <IoInformationCircle
                                            style={{
                                                color: colors.primary.CERULEAN,
                                            }}
                                            size="1.25rem"
                                        />
                                    }
                                    helpText={item.description}
                                    color={colors.primary.CERULEAN}
                                />
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <Typography> {item.quantity} </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography textAlign="center">
                            ${(item.amount / 100).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
            <Box width="90%" paddingY={4} mx="auto">
                <Typography paddingBottom={2}>
                    <strong>Please note:</strong> Any additional seats that are
                    added at the end of checkout will remain empty until used.
                    To add a new user, go to Organization Settings.
                </Typography>
                <Typography>
                    Any unused seats will continue to be charged to your
                    organization's payment method on file until removed from
                    your organization.
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                {!props.hideReturnToDashboard ? (
                    <Button href="/" variant="text">
                        <Typography fontWeight={600}>
                            Return to Dashboard
                        </Typography>
                    </Button>
                ) : null}
                <Button
                    href="/settings/organization"
                    color="primary"
                    variant="contained"
                >
                    Assign Seats
                </Button>
            </Box>
        </Box>
    )
}
