import React, { FC } from 'react'
import { QueryBuilderValues, SurveyAnswerInsightView } from '../organisms'
import { InsightViewProps } from '../molecules'
import { PickerItem } from '../../lib/entities'
import { SurveyAnswerInsight } from '@hazadapt-git/public-core-base'

interface SurveyAnswerInsightTemplateProps {
    prepCheckAreasForQueryBuilder: PickerItem<string>[]
    selectedPrepCheck?: number
    selectedArea?: string
    organizationName: string
    queryId?: number
    queryItems: PickerItem<number | undefined>[]
    onRunQueryPress(values: QueryBuilderValues): void
    startDate: Date | null
    endDate: Date | null
    onReloadPress(): void
    updatedDate?: Date
    onRunQueryPress(values: QueryBuilderValues): void
    onResetQueryPress: React.MouseEventHandler
    onSaveQueryPress: React.MouseEventHandler
    onDownloadPress: React.MouseEventHandler
    onBack: React.MouseEventHandler
    onNextQuestionPress(): void
    onPrevQuestionPress(): void
    selectedLocationString: string
    selectedDatesString: string
    insights?: InsightViewProps[]
    surveyData?: SurveyAnswerInsight
    quickStatRowTitle?: string
    loading?: boolean
    minQueryDate: Date
    desktopMenuOpen?: boolean
}

export const SurveyAnswerInsightTemplate: FC<
    SurveyAnswerInsightTemplateProps
> = (props: SurveyAnswerInsightTemplateProps) => {
    return (
        <SurveyAnswerInsightView
            organizationName={props.organizationName}
            queryItemTitle="Prep Check"
            queryId={props.queryId}
            selectedQueryItem={props.selectedPrepCheck}
            queryItems={props.queryItems}
            queryItemSelectorPlaceholder="Select a Prep Check..."
            selectedArea={props.selectedArea}
            communityAreasForQueryBuilder={props.prepCheckAreasForQueryBuilder}
            areaSelectorPlaceholder="Select an area..."
            startDate={props.startDate}
            endDate={props.endDate}
            onReloadPress={props.onReloadPress}
            updatedDate={props.updatedDate}
            onRunQueryPress={props.onRunQueryPress}
            onResetQueryPress={props.onResetQueryPress}
            onSaveQueryPress={props.onSaveQueryPress}
            onDownloadPress={props.onDownloadPress}
            selectedLocationString={props.selectedLocationString}
            selectedDatesString={props.selectedDatesString}
            insights={props.insights}
            quickStatRowTitle={props.quickStatRowTitle}
            loading={props.loading}
            minQueryDate={props.minQueryDate}
            desktopMenuOpen={props.desktopMenuOpen}
            data={props.surveyData}
            onBack={props.onBack}
            onPrevQuestionPress={props.onPrevQuestionPress}
            onNextQuestionPress={props.onNextQuestionPress}
            mapDefaultZoom={1}
            mapDefaultCenter={[]}
            onSwitchMapColorTheme={() => {}}
        />
    )
}
