import {
    Insight,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
} from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'

import { HomePageTemplate, LoadingView } from '../components'
import { NamespacedPageProps, RefreshInsightArgs } from '../lib/entities'
import { useAppSelector } from '../lib/store'
import {
    getInsights,
    useBuildInsightGridData,
    refreshInsight,
    exportInsights,
    persistLocalPickerChangesOnNewConfig,
} from '../lib/utils/insights'
import { getEnvVars } from '../lib/config'
import { getHazardData, getPrepCheckData } from '../lib/utils/cms'
import { useSearchParams } from 'react-router-dom'
import { formatDateRange } from '../lib/utils'

interface HomePageProps extends NamespacedPageProps {}

const { publicAppUriBase } = getEnvVars()
export const HomePage: FC<HomePageProps> = ({
    user,
    organization,
    loading,
}) => {
    const [searchParams] = useSearchParams()
    // States
    const [insightData, setInsightData] = React.useState<Insight[]>()
    const [quickStatRowTitle, setQuickStatRowTitle] = React.useState<string>()
    const [activeQuery, setActiveQuery] = React.useState<InsightQueryDTO>()
    const [retrievingInsights, setRetrievingInsights] =
        React.useState<boolean>(true)

    // Store data
    const trendingHazards = useAppSelector(
        (state) => state.content.trendingHazards
    )
    const hasLocalContent = useAppSelector(
        (state) => state.content.has_local_content
    )

    const buildInsightGridData = useBuildInsightGridData()

    const refreshInsights = React.useCallback(() => {
        if (!activeQuery) return
        setRetrievingInsights(true)
        getInsights(
            InsightQueryType.CORE,
            undefined,
            activeQuery.id > 0 && activeQuery.start_date
                ? new Date(activeQuery.start_date)
                : undefined,
            activeQuery.id > 0 && activeQuery.end_date
                ? new Date(activeQuery.end_date)
                : undefined,
            activeQuery.hazards,
            activeQuery.prep_checks,
            activeQuery.zips
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...newQuery } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                persistLocalPickerChangesOnNewConfig(
                    newQuery.grid_config,
                    activeQuery.grid_config
                )
                setActiveQuery(newQuery)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [activeQuery])

    React.useEffect(() => {
        document.title = 'Home - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        getHazardData()
        getPrepCheckData()
    }, [organization])

    React.useEffect(() => {
        getInsights(
            InsightQueryType.CORE,
            searchParams,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [searchParams])

    const openTrendingHazard = (id: number): void => {
        window.open(`${publicAppUriBase}/hazards/${id}`, '_blank')
    }

    const refreshOneInsight = React.useCallback(
        async (
            placement_id: string,
            args: InsightQueryBase & RefreshInsightArgs
        ) => {
            args.grid_config = {
                ...args.grid_config,
                [placement_id]: {
                    ...args.grid_config[placement_id],
                    bbox: args.bbox,
                    zoom: args.zoom,
                },
            }
            delete args.bbox
            delete args.zoom
            setActiveQuery((aq) =>
                aq
                    ? {
                          ...aq,
                          ...args,
                      }
                    : undefined
            )
            const newInsight = await refreshInsight(
                placement_id,
                InsightQueryType.CORE,
                args
            )
            const currInsights = [...(insightData ?? [])]
            const index = currInsights.findIndex(
                (i) => i.placement_id === placement_id
            )
            if (index < 0) currInsights.push(newInsight)
            else currInsights.splice(index, 1, newInsight)
            setInsightData(currInsights)
        },
        [insightData]
    )

    const insights = React.useMemo(
        () => buildInsightGridData(insightData, activeQuery, refreshOneInsight),
        [buildInsightGridData, insightData, activeQuery, refreshOneInsight]
    )

    return organization && user ? (
        <HomePageTemplate
            pageTitle={`Overall HazAdapt Activity - ${organization.name}`}
            dateRange={formatDateRange(
                activeQuery?.start_date,
                activeQuery?.end_date
            )}
            trendingHazardData={trendingHazards}
            showLocalContentDisclaimer={hasLocalContent}
            onReloadPress={refreshInsights}
            updatedDate={
                activeQuery ? new Date(activeQuery.date_last_used) : undefined
            }
            onDownloadPress={() =>
                exportInsights(
                    InsightQueryType.CORE,
                    organization.slug,
                    activeQuery
                )
            }
            insights={insights}
            quickStatRowTitle={quickStatRowTitle}
            loading={loading}
            retrievingInsights={retrievingInsights}
            onPressTrendingHazard={openTrendingHazard}
        />
    ) : (
        <LoadingView />
    )
}
