import React, { FC } from 'react'

import { LoadingView, OrganizationDetailsTemplate } from '../components'
import { NamespacedPageProps, NewRPUser } from '../lib/entities'
import {
    OrganizationWithMembers,
    RPUser,
    colors,
    isEqual,
} from '@hazadapt-git/public-core-base'
import {
    getFullOrganization,
    inviteUser,
    removeUser,
    toast,
    updateOrganization,
    updateOrganizationLogo,
} from '../lib/utils'
import { useNavigate } from 'react-router'
import {
    errorColor,
    primaryIconSize,
    successColor,
} from '../lib/styles/universal'
import {
    IoAlertCircle,
    IoCheckmarkCircle,
    IoCloudUploadOutline,
} from 'react-icons/io5'
import { AxiosError } from 'axios'

interface OrganizationDetailsProps extends NamespacedPageProps {}

export const SetupOrganizationPage: FC<OrganizationDetailsProps> = ({
    user,
}) => {
    const navigate = useNavigate()

    const [organization, setOrganization] =
        React.useState<OrganizationWithMembers | null>()
    const [uneditedOrganization, setUneditedOrganization] =
        React.useState<OrganizationWithMembers | null>()
    const [pendingInvitations, setPendingInvitations] = React.useState<
        NewRPUser[]
    >([])
    const [changes, setChanges] = React.useState<boolean>(false)

    React.useEffect(() => {
        getFullOrganization()
            .then((org) => {
                setOrganization(org)
                setUneditedOrganization(org)
            })
            .catch((err) => {
                console.error(err)
                setOrganization(null)
                setUneditedOrganization(null)
            })
    }, [])

    React.useEffect(() => {
        document.title = 'Setup Organization - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        const checkForChanges = () => {
            if (
                pendingInvitations.length > 0 ||
                isEqual(organization, uneditedOrganization) === false
            ) {
                setChanges(true)
            } else {
                setChanges(false)
            }
        }
        checkForChanges()
    }, [pendingInvitations, organization, uneditedOrganization])

    const onSaveLogoClick = React.useCallback(
        async (file: File) => {
            if (!organization) return
            try {
                const updatedOrg = await updateOrganizationLogo(file)
                setOrganization(updatedOrg)
            } catch (err) {
                console.error(err)
            }
        },
        [organization]
    )

    const onConfirmSkip = React.useCallback(async () => {
        if (!uneditedOrganization) return
        updateOrganization({
            ...uneditedOrganization,
            status: 'active',
        })
            .then(() => {
                navigate('/')
            })
            .catch((err: AxiosError) => {
                console.error(err)
                toast(
                    err.message,
                    <IoAlertCircle size={primaryIconSize} color={errorColor} />
                )
            })
    }, [navigate, uneditedOrganization])

    const onDoneClick = React.useCallback(async () => {
        if (!organization) return
        if (!organization.logo) {
            toast(
                `Organization logo is required, please add one to continue.`,
                <IoAlertCircle size={primaryIconSize} color={errorColor} />
            )
            return
        }

        toast(
            'Saving...',
            <IoCloudUploadOutline
                color={colors.primary.BLUEBERRY}
                size={primaryIconSize}
            />
        )
        try {
            const members = await inviteUser([...pendingInvitations])
            const updatedOrg = await updateOrganization({
                ...organization,
                status: 'active',
                members,
            })
            setOrganization(updatedOrg)
            toast(
                `You've finished setting up your organization! Let's go!`,
                <IoCheckmarkCircle
                    size={primaryIconSize}
                    color={successColor}
                />
            )
            navigate('/')
        } catch (err) {
            const error = err as AxiosError
            console.error(error)
            toast(
                error.message,
                <IoAlertCircle size={primaryIconSize} color={errorColor} />
            )
        }
    }, [organization, navigate, pendingInvitations])

    const onSubmitNewUser = (newUser: NewRPUser) => {
        if (!organization) return

        setPendingInvitations([...pendingInvitations, newUser])
    }

    const onRemoveUser = React.useCallback(
        async (user: RPUser) => {
            if (!organization) return
            try {
                const newMemberList = await removeUser([user.rp_user_id])
                if (newMemberList) {
                    const org = { ...organization }
                    setOrganization({
                        ...org,
                        members: newMemberList,
                    })
                    toast(
                        `${user.first_name} ${user.last_name} has been removed from your organization.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                }
            } catch (e) {
                toast(
                    `There was a problem removing ${user.first_name} ${user.last_name} from your organization. Please try again.`,
                    <IoAlertCircle color={errorColor} size={primaryIconSize} />
                )
            }
        },
        [organization]
    )

    const onSaveUserChanges = (user: RPUser): void => {
        if (!organization) return
        const members = [...organization.members]
        const idx = members.findIndex((m) => m.rp_user_id === user.rp_user_id)
        if (idx < 0) return
        members[idx] = {
            ...members[idx],
            ...user,
        }
        setOrganization({
            ...organization,
            members,
        })
    }

    return user && organization ? (
        <OrganizationDetailsTemplate
            activeUser={user}
            organization={organization}
            mode="setup"
            onSaveLogoClick={onSaveLogoClick}
            onDoneClick={onDoneClick}
            onConfirmSkip={onConfirmSkip}
            onSubmitNewUser={onSubmitNewUser}
            onRemoveUser={onRemoveUser}
            onSaveUserChanges={onSaveUserChanges}
            pendingChanges={changes}
        />
    ) : user === null || organization === null ? null : (
        <LoadingView />
    )
}
