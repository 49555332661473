import React, { FC } from 'react'

import { LoadingView, SetUpWizardWelcomePageTemplate } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { login, signup } from '../lib/utils/auth'
import {
    OrganizationInviteDTO,
    OrganizationOverview,
} from '@hazadapt-git/public-core-base'
import { useNavigate, useParams } from 'react-router'
import { getInvite, getSplashOrgDetails } from '../lib/utils'

interface SetupWizardWelcomePageProps extends NamespacedPageProps {}

export const SetupWizardWelcomePage: FC<SetupWizardWelcomePageProps> = ({
    loading,
}) => {
    const { inviteCode } = useParams()
    const navigate = useNavigate()
    const [invite, setInvite] = React.useState<OrganizationInviteDTO>()
    const [organization, setOrganization] =
        React.useState<OrganizationOverview>()

    React.useEffect(() => {
        if (!organization) return
        document.title = `Join ${organization.name} on ResiliencePoint`
    }, [organization])

    React.useEffect(() => {
        if (!inviteCode) {
            navigate('/')
            return
        }
        getSplashOrgDetails()
            .then(setOrganization)
            .catch((err) => {
                console.error(err)
                navigate('/')
            })
        getInvite(inviteCode)
            .then(setInvite)
            .catch((err) => {
                console.error(err)
                navigate('/')
            })
    }, [inviteCode, navigate])

    const handleLogin = async () => {
        const redirectUrl = `${window.location.protocol}//${window.location.host}/setup/profile`
        await login(redirectUrl)
    }
    const handleSignUp = async () => {
        await signup()
    }

    return !loading && organization && invite ? (
        <SetUpWizardWelcomePageTemplate
            organization={organization}
            onLogin={handleLogin}
            onSignUp={handleSignUp}
            inviterName={invite.inviter_name}
        />
    ) : (
        <LoadingView />
    )
}
