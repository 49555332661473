import { QuickStatInsight } from '@hazadapt-git/public-core-base'
import { Box, Link, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import Color from 'color'
import React, { FC, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { theme } from '../../lib/styles/universal'

export interface QuickStatInsightViewProps
    extends Omit<QuickStatInsight, 'headline'> {
    headline: React.ReactNode
}

export const QuickStatInsightView: FC<QuickStatInsightViewProps> = (props) => {
    const { classes: localClasses } = useLocalStyles()

    const [fontVariant, setFontVariant] = React.useState<Variant>('h1')

    const titleId = `${props.placement_id}__headline`
    React.useEffect(() => {
        const container = document.getElementById(props.placement_id)
        const title = document.getElementById(titleId)

        if (!container || !title) return
        const browserFontSize = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        )
        if (
            title.offsetHeight > 2.5 * browserFontSize ||
            title.offsetWidth > 0.8 * container.offsetWidth
        ) {
            setFontVariant('h3')
        } else if (title.offsetWidth > 0.6 * container.offsetWidth) {
            setFontVariant('h2')
        } else {
            setFontVariant('h1')
        }
    }, [titleId, props.placement_id])

    const Content = useMemo(
        () => (
            <Box
                className={localClasses.container}
                sx={{
                    color: props.color,
                    borderColor: props.color,
                    backgroundColor: Color(props.color)
                        .fade(0.8)
                        .lighten(0.8)
                        .string(),
                }}
                id={props.placement_id}
            >
                <div className={localClasses.valueAndIcon}>
                    <img
                        src={
                            props.icon.url ||
                            `data:image/svg+xml;base64,${btoa(props.icon.src)}`
                        }
                        alt={props.icon.alt}
                        className={localClasses.icon}
                    />
                    <div className={localClasses.valueContainer}>
                        <Typography
                            variant={fontVariant}
                            color={props.color}
                            textAlign="right"
                            id={titleId}
                            component="p"
                            sx={{ wordWrap: 'break-word' }}
                        >
                            {props.value}
                        </Typography>
                        {props.unit && (
                            <Typography
                                fontWeight={500}
                                textAlign="right"
                                sx={{ wordWrap: 'break-word' }}
                            >
                                {props.unit}
                            </Typography>
                        )}
                    </div>
                </div>
                {props.headline ? (
                    <Typography fontWeight={500}>{props.headline}</Typography>
                ) : null}
            </Box>
        ),
        [
            localClasses,
            fontVariant,
            props.color,
            props.headline,
            props.icon,
            props.placement_id,
            props.unit,
            props.value,
            titleId,
        ]
    )

    return props.href ? (
        <Link
            href={props.href}
            target="_blank"
            underline="none"
            sx={{ alignItems: 'stretch' }}
            flex={1}
        >
            {Content}
        </Link>
    ) : (
        Content
    )
}

const useLocalStyles = makeStyles()({
    container: {
        flex: 1,
        padding: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '1rem',
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: '0.5rem',
        minWidth: '10rem',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            minWidth: '14rem',
        },
    },
    valueAndIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.75rem',
    },
    valueContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
    },
    icon: {
        width: '2.5rem',
        height: '2.5rem',
        objectFit: 'contain',
        objectPosition: 'center',
        [theme.breakpoints.up('md')]: {
            width: '3rem',
            height: '3rem',
        },
        [theme.breakpoints.up('lg')]: {
            width: '4rem',
            height: '4rem',
        },
    },
})
