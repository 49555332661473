import { RPUser, colors } from '@hazadapt-git/public-core-base'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material'
import { RPLogo } from '../atoms'
import { ProfileLinkWithAvatar } from '../molecules'
import { NavBarItem } from '../../lib/entities'
import classNames from 'classnames'
import HazAdaptLogo from './../../assets/images/HazAdaptLogoRW.svg'
import SmallHazAdaptLogo from '../../assets/images/HazAdaptAppIcon.svg'
import SmallPRLogo from './../../assets/images/rp_initials_logo.svg'
import {
    desktopMenuClosedWidth,
    desktopMenuOpenWidth,
    primaryIconSize,
} from '../../lib/styles/universal'
import { MdMenuOpen } from 'react-icons/md'
import { RootState, useAppSelector } from '../../lib/store'

interface DesktopSiteNavProps {
    user?: RPUser
    onToggleMenu(): void
    onLogoPress(): void
    navItems: NavBarItem[]
    onNavItemPress(route: string): void
    activeNavItem: string
}

export const DesktopSiteNav: FC<DesktopSiteNavProps> = ({
    user,
    onToggleMenu,
    onLogoPress,
    navItems,
    onNavItemPress,
    activeNavItem,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const { desktopMenuOpen } = useAppSelector((state: RootState) => state.base)

    const handleNavItemPress = (e: React.MouseEvent, route: string) => {
        e.stopPropagation()
        onNavItemPress(route)
    }

    const handleLogoPress = (e: React.MouseEvent) => {
        e.stopPropagation()
        onLogoPress()
    }

    const handleLinkPress = (e: React.MouseEvent) => {
        e.stopPropagation()
        window.location.href = 'https://hazadapt.com'
    }

    const handleAvatarPress = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    if (!user) return null

    return (
        <Drawer
            onClick={onToggleMenu}
            variant="permanent"
            sx={{
                '& .MuiDrawer-paper': {
                    width: desktopMenuOpen
                        ? desktopMenuOpenWidth
                        : desktopMenuClosedWidth,
                    backgroundColor: colors.primary.CERULEAN,
                    transition: 'all 0.225s ease',
                },
            }}
        >
            <Box
                className={localClasses.avatarContainer}
                sx={{
                    alignSelf: desktopMenuOpen ? 'flex-end' : 'center',
                }}
                onClick={handleAvatarPress}
            >
                <ProfileLinkWithAvatar
                    src={user.profile_picture_uri ?? undefined}
                    name={user.first_name}
                />
            </Box>
            <Box
                className={localClasses.logoContainer}
                sx={{
                    justifyContent: desktopMenuOpen ? 'start' : 'center',
                }}
            >
                {desktopMenuOpen ? (
                    <RPLogo
                        onClick={handleLogoPress}
                        className={localClasses.rpLogo}
                    />
                ) : (
                    <Box onClick={handleLogoPress} sx={{ cursor: 'pointer' }}>
                        <img
                            src={SmallPRLogo}
                            alt={'ResiliencePoint Logo'}
                            className={localClasses.smallRPLogo}
                        />
                    </Box>
                )}
            </Box>
            <Box className={localClasses.content}>
                {navItems
                    .filter((item) => !item.hide)
                    .map((item, index) => (
                        <div
                            onClick={(e) => handleNavItemPress(e, item.route)}
                            className={localClasses.navBarLinkContainer}
                            key={item.label}
                        >
                            {item.icon && (
                                <Stack>
                                    {item.icon}
                                    <Box
                                        className={
                                            localClasses.activeItemIndicator
                                        }
                                        sx={{
                                            backgroundColor:
                                                !desktopMenuOpen &&
                                                activeNavItem.startsWith(
                                                    item.route
                                                )
                                                    ? colors.grays.BLANC
                                                    : 'inherit',
                                        }}
                                    />
                                </Stack>
                            )}
                            {desktopMenuOpen && (
                                <Typography
                                    component="div"
                                    className={localClasses.navBarLink}
                                >
                                    {item.label}
                                    <Box
                                        className={
                                            localClasses.activeItemIndicator
                                        }
                                        sx={{
                                            backgroundColor:
                                                activeNavItem.startsWith(
                                                    item.route
                                                )
                                                    ? colors.grays.BLANC
                                                    : 'inherit',
                                        }}
                                    />
                                </Typography>
                            )}
                        </div>
                    ))}
            </Box>
            <IconButton
                onClick={onToggleMenu}
                aria-label={`${desktopMenuOpen ? 'Collapse' : 'Expand'} menu`}
                sx={{
                    alignSelf: desktopMenuOpen ? 'flex-end' : 'center',
                    bottom: '3.5rem',
                    marginRight: desktopMenuOpen ? '.5rem' : '',
                    position: 'absolute',
                }}
            >
                <MdMenuOpen
                    className={classNames(localClasses.toggleMenuIcon, {
                        [localClasses.rotatedIcon]: !desktopMenuOpen,
                    })}
                />
            </IconButton>
            <Box className={localClasses.footer} onClick={handleLinkPress}>
                {desktopMenuOpen ? (
                    <>
                        <Typography className={localClasses.footerText}>
                            Powered By
                        </Typography>
                        <img
                            src={HazAdaptLogo}
                            alt={'HazAdapt Logo'}
                            className={localClasses.footerLogo}
                        />
                    </>
                ) : (
                    <img
                        src={SmallHazAdaptLogo}
                        alt={'HazAdapt Logo'}
                        className={localClasses.smallFooterLogo}
                    />
                )}
            </Box>
        </Drawer>
    )
}

const useLocalStyles = makeStyles()({
    avatarContainer: {
        padding: '1rem',
    },
    logoContainer: { display: 'flex', height: '2rem', marginTop: '2rem' },
    rpLogo: {
        cursor: 'pointer',
        height: '1.5rem',
        marginLeft: '1rem',
        width: 'auto',
    },
    smallRPLogo: { height: '1.75rem' },
    toggleMenuIcon: {
        color: colors.grays.BLANC,
        size: primaryIconSize,
        transition: 'transform 0.3s ease',
    },
    rotatedIcon: { transform: 'rotate(180deg)' },
    content: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        marginLeft: '1rem',
        marginTop: '2rem',
    },
    footer: {
        alignItems: 'center',
        bottom: 0,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: '.5rem',
        position: 'absolute',
        width: '100%',
    },
    footerText: {
        color: colors.grays.BLANC,
        fontSize: '1rem',
        fontWeight: 500,
        paddingBottom: '.25rem',
        paddingRight: '1rem',
        whiteSpace: 'nowrap',
    },
    footerLogo: { height: '2rem', width: 'auto' },
    smallFooterLogo: {
        height: '2.5rem',
        borderRadius: 50,
    },
    navBarLinkContainer: {
        alignItems: 'center',
        color: colors.grays.BLANC,
        cursor: 'pointer',
        display: 'flex',
        gap: '.5rem',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    navBarLink: {
        color: 'inherit',
        fontWeight: 500,
    },
    activeItemIndicator: {
        boxSizing: 'border-box',
        height: '1.5px',
        width: '100%',
    },
})
