import {
    AdoptionStatsDTO,
    CommunityAdoptionInterval,
    CommunityAdoptionStat,
    ExportFormat,
    Insight,
    InsightGridConfig,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    InsightsDTO,
    JurisdictionChunkProperties,
    LanguageCode,
    MapInsightItem,
    colors,
} from '@hazadapt-git/public-core-base'
import { AxiosError, AxiosResponse } from 'axios'
import { isa } from '../api'
import { getEnvVars } from '../config'
import { InsightViewProps } from '../../components/molecules/InsightView'
import { Picker } from '../../components/atoms/Picker'
import { useAppSelector } from '../store'
import {
    IoAlertCircle,
    IoCheckmarkCircle,
    IoCloudDownloadOutline,
    IoDocument,
    IoImage,
} from 'react-icons/io5'
import { MenuItemProps, RefreshInsightArgs } from '../entities'
import { useCallback } from 'react'
import { Feature, Geometry } from 'geojson'
import { toast } from './misc'
import {
    errorColor,
    infoToastColor,
    primaryIconSize,
} from '../styles/universal'
import { useNavigate } from 'react-router'
import { isToday, startOfDay } from 'date-fns'

const { apiUrl } = getEnvVars()

export const getInsights = async (
    type: InsightQueryType = InsightQueryType.CORE,
    searchParams?: URLSearchParams,
    start_date?: Date,
    end_date: Date = new Date(),
    hazards: number[] = [],
    prep_checks: number[] = [],
    zips: string[] = [],
    reset = false,
    insight_ids: string[] = [],
    language: LanguageCode = LanguageCode.ENGLISH,
    recenter_map = false
): Promise<InsightsDTO> => {
    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/insights`)
    url.searchParams.set('type', type)
    if (searchParams) {
        Array.from(searchParams.entries()).forEach(([key, val]) => {
            if (key !== 'type') url.searchParams.set(key, val)
        })
    } else {
        if (start_date)
            url.searchParams.set(
                'start_date',
                startOfDay(new Date(start_date)).toISOString()
            )
        if (isToday(new Date(end_date))) {
            url.searchParams.set('end_date', new Date().toISOString())
        } else {
            url.searchParams.set('end_date', new Date(end_date).toISOString())
        }
        url.searchParams.set('hazards', hazards.toString())
        url.searchParams.set('prep_checks', prep_checks.toString())
        url.searchParams.set('zips', zips.toString())
        if (insight_ids.length > 0) {
            url.searchParams.set('insights', insight_ids.toString())
        }
        url.searchParams.set('language', language)
    }
    if (reset) url.searchParams.set('reset', reset.toString())
    if (recenter_map) url.searchParams.set('recenter', 'true')
    try {
        const response: AxiosResponse<InsightsDTO> = await isa.get(
            url.toString(),
            { timeout: 30000 }
        )
        return response.data
    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 504) {
            toast(
                'Unable to get query results. Please try again later.',
                <IoAlertCircle color={errorColor} size={primaryIconSize} />
            )
        }
        throw error
    }
}

export const getSingleInsight = async (
    type: InsightQueryType = InsightQueryType.PREP_CHECK_QUESTION,
    searchParams?: URLSearchParams,
    start_date?: Date,
    end_date: Date = new Date(),
    hazards: number[] = [],
    prep_checks: number[] = [],
    zips: string[] = [],
    reset = false,
    href: string = '',
    language: LanguageCode = LanguageCode.ENGLISH,
    recenter_map = false
): Promise<InsightsDTO> => {
    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/insights/${href}`)

    url.searchParams.set('type', type)
    if (searchParams) {
        Array.from(searchParams.entries()).forEach(([key, val]) => {
            if (key !== 'type') url.searchParams.set(key, val)
        })
    } else {
        if (start_date) {
            url.searchParams.set(
                'start_date',
                startOfDay(new Date(start_date)).toISOString()
            )
        }
        if (isToday(new Date(end_date))) {
            url.searchParams.set('end_date', new Date().toISOString())
        } else {
            url.searchParams.set('end_date', new Date(end_date).toISOString())
        }
        url.searchParams.set('hazards', hazards.toString())
        url.searchParams.set('prep_checks', prep_checks.toString())
        url.searchParams.set('zips', zips.toString())
        url.searchParams.set('language', language)
    }
    if (reset) url.searchParams.set('reset', reset.toString())
    if (recenter_map) url.searchParams.set('recenter', 'true')
    try {
        const response: AxiosResponse<InsightsDTO> = await isa.get(
            url.toString(),
            { timeout: 30000 }
        )
        return response.data
    } catch (err) {
        const error = err as AxiosError
        if (error.response?.status === 504) {
            toast(
                'Unable to get query results. Please try again later.',
                <IoAlertCircle color={errorColor} size={primaryIconSize} />
            )
        }
        throw error
    }
}

export const saveQuery = async (
    type: InsightQueryType,
    start_date?: Date,
    end_date: Date = new Date(),
    hazards: number[] = [],
    prep_checks: number[] = [],
    zips: string[] = [],
    grid_config: InsightGridConfig = {},
    insight_ids: string[] = []
): Promise<void> => {
    const response = await isa.post('/query', {
        type,
        start_date,
        end_date,
        hazards,
        prep_checks,
        zips,
        grid_config,
        insights: insight_ids,
    })

    if (response.status === 201) {
        toast(
            'Query saved.',
            <IoCheckmarkCircle
                color={colors.primary.WAIKATO}
                size={primaryIconSize}
            />
        )
    } else {
        toast(
            'Unable to save query.',
            <IoAlertCircle color={errorColor} size={primaryIconSize} />
        )
    }
}

export const getCommunityAdoptionStat = async (
    stat: CommunityAdoptionStat,
    frequency: CommunityAdoptionInterval,
    zip?: string
): Promise<AdoptionStatsDTO> => {
    const url = new URL(
        `${isa.defaults.baseURL ?? apiUrl}/stats/community-adoption`
    )
    url.searchParams.append('frequency', frequency)
    url.searchParams.append('stat', stat)
    if (zip && zip !== 'jurisdiction') {
        url.searchParams.append('zips', zip)
    }
    const response: AxiosResponse<AdoptionStatsDTO> = await isa.get(
        url.toString()
    )
    return response.data
}

export const getPrepCheckUsageStat = async (
    frequency: CommunityAdoptionInterval,
    area?: string
): Promise<AdoptionStatsDTO> => {
    const url = new URL(
        `${isa.defaults.baseURL ?? apiUrl}/stats/prep-check-usage`
    )
    url.searchParams.append('frequency', frequency)
    if (area) {
        url.searchParams.append('area', area)
    }
    const response: AxiosResponse<AdoptionStatsDTO> = await isa.get(
        url.toString()
    )
    return response.data
}

export const insightOptions = (
    organization_slug: string,
    type: InsightQueryType,
    placement_id: string,
    query?: InsightQueryDTO
): MenuItemProps[] => [
    {
        label: 'Download as PNG',
        icon: <IoImage size={primaryIconSize} />,
        onClick: () =>
            exportInsights(
                type,
                organization_slug,
                query,
                ExportFormat.PNG,
                [placement_id],
                LanguageCode.ENGLISH
            ),
    },
    {
        label: 'Download as PDF',
        icon: <IoDocument size={primaryIconSize} />,
        onClick: () =>
            exportInsights(
                type,
                organization_slug,
                query,
                ExportFormat.PDF,
                [placement_id],
                LanguageCode.ENGLISH
            ),
    },
]

const handleUpdateSelection = async (
    key: string,
    value: string,
    placement_id: string,
    activeQuery: InsightQueryBase,
    onUpdate?: (
        placement_id: string,
        args: InsightQueryBase
    ) => void | Promise<void>
) => {
    const config = { ...activeQuery.grid_config[placement_id] }
    if (!(key in config.pickers) || !activeQuery) return
    config.pickers[key].selected_option = value
    await onUpdate?.(placement_id, {
        ...activeQuery,
        grid_config: {
            ...activeQuery.grid_config,
            [placement_id]: config,
        },
    })
}

export const useTransformRawHeadlineToNode = () => {
    const transformRawHeadlineToNode = useCallback(
        (
            raw_headline: string | undefined,
            placement_id: string,
            activeQuery: InsightQueryBase,
            onUpdate: (
                placement_id: string,
                args: InsightQueryBase
            ) => void | Promise<void>
        ): React.ReactNode => {
            if (!raw_headline) return null
            const pieces: string[] = raw_headline.split(
                /(?!{.*)\s(?![^{]*?\})/g
            )

            const results: React.ReactNode[] = []
            pieces.forEach((piece, index) => {
                if (!/{(.*?)}/g.test(piece)) {
                    // Just a string, not a picker placeholder, save and continue
                    results.push(index < pieces.length ? `${piece} ` : piece)
                    return
                }
                // We have a picker placeholder
                const key = piece.replaceAll(/{|}/g, '')
                switch (key) {
                    case 'date_range': {
                        // TODO
                        break
                    }
                    default: {
                        if (
                            !activeQuery.grid_config[placement_id]?.pickers[key]
                        )
                            break
                        const insight_config =
                            activeQuery.grid_config[placement_id]
                        const result: React.ReactNode = (
                            <Picker
                                key={key}
                                data={insight_config.pickers[key].options}
                                id={key}
                                value={
                                    insight_config.pickers[key].selected_option
                                }
                                onChange={(value: string) =>
                                    handleUpdateSelection(
                                        key,
                                        value,
                                        placement_id,
                                        activeQuery,
                                        onUpdate
                                    )
                                }
                                selectColor={colors.chips.LIGHTSEA}
                                variableSize
                                variant="outlined"
                            />
                        )
                        results.push(result)
                        break
                    }
                }
            })
            const finalChunks: React.ReactNode[] = []
            results.forEach((r) => {
                if (
                    finalChunks.length === 0 ||
                    typeof r !== 'string' ||
                    typeof r !== typeof finalChunks.at(-1)
                ) {
                    finalChunks.push(r)
                    return
                }
                finalChunks[finalChunks.length - 1] += r
            })
            return finalChunks
        },
        []
    )

    return transformRawHeadlineToNode
}

export const useBuildInsightGridData = () => {
    const organization = useAppSelector((state) => state.base.organization)
    const transformRawHeadlineToNode = useTransformRawHeadlineToNode()
    const navigate = useNavigate()

    const handleCtaClick = useCallback(
        (href: string) => {
            navigate(href)
        },
        [navigate]
    )

    const buildInsightGridData = useCallback(
        (
            insights: Insight[] | undefined,
            activeQuery: InsightQueryDTO | undefined,
            onUpdate: (
                placement_id: string,
                args: InsightQueryBase & RefreshInsightArgs
            ) => void | Promise<void>
        ): InsightViewProps[] | undefined => {
            if (!organization || !insights || !activeQuery) return undefined
            const insightGridData: InsightViewProps[] = []

            for (const insight of insights) {
                switch (insight.type) {
                    case 'single-counter': {
                        break
                    }
                    case 'survey-answer': {
                        break
                    }
                    case 'map': {
                        // MapInsight

                        let centerPiece:
                            | Feature<
                                  Geometry,
                                  MapInsightItem | JurisdictionChunkProperties
                              >
                            | undefined = insight.map_config.features.find(
                            (f) => f.geometry.type === 'Point'
                        )
                        if (
                            centerPiece?.geometry?.type !== 'Point' &&
                            !(activeQuery.zips && activeQuery.zips.length > 0)
                        ) {
                            // No specific point on insight.map_config, and querying over specific area;
                            // do not reset center to org jurisdiction center
                            centerPiece =
                                organization.jurisdiction.features.find(
                                    (f) => f.geometry.type === 'Point'
                                )
                        }

                        const insightView: InsightViewProps = {
                            ...insight,
                            config: activeQuery.grid_config[
                                insight.placement_id
                            ],
                            options: insightOptions(
                                organization.slug,
                                activeQuery.type,
                                insight.placement_id,
                                activeQuery
                            ),
                            ctas: insight.ctas,
                            handleCtaClick,
                            headline: transformRawHeadlineToNode(
                                insight.headline,
                                insight.placement_id,
                                activeQuery,
                                onUpdate
                            ),
                            defaultZoom: centerPiece?.properties.defaultZoom,
                            defaultCenter:
                                centerPiece?.geometry?.type === 'Point'
                                    ? centerPiece?.geometry.coordinates
                                    : undefined,
                            onSwitchVariant: async (value) => {
                                await handleUpdateSelection(
                                    'variant',
                                    value,
                                    insight.placement_id,
                                    activeQuery,
                                    onUpdate
                                )
                            },
                            onSwitchColorTheme: (value) => {
                                handleUpdateSelection(
                                    'theme',
                                    value,
                                    insight.placement_id,
                                    activeQuery
                                )
                            },
                            onUpdate: (pid, bbox, zoom) =>
                                onUpdate(pid, { ...activeQuery, bbox, zoom }),
                        }
                        insightGridData.push(insightView)
                        break
                    }
                    default: {
                        const insightView: InsightViewProps = {
                            ...insight,
                            type: insight.type,
                            options: insightOptions(
                                organization.slug,
                                activeQuery.type,
                                insight.placement_id,
                                activeQuery
                            ),
                            ctas: insight.ctas, // TODO
                            handleCtaClick,
                            headline: transformRawHeadlineToNode(
                                insight.headline,
                                insight.placement_id,
                                activeQuery,
                                onUpdate
                            ),
                        } as InsightViewProps
                        insightGridData.push(insightView)
                        break
                    }
                }
            }

            return insightGridData
        },
        [organization, transformRawHeadlineToNode, handleCtaClick]
    )
    return buildInsightGridData
}

export const refreshInsight = async (
    placement_id: string,
    type: InsightQueryType,
    args: InsightQueryBase
): Promise<Insight> => {
    const response: AxiosResponse<Insight> = await isa.post(
        `/insights/${placement_id}`,
        {
            ...args,
            type,
        }
    )
    return response.data
}

export const exportInsights = async (
    type: InsightQueryType,
    organization_slug: string,
    query?: InsightQueryDTO,
    format: ExportFormat = ExportFormat.PDF,
    insights: string[] = [],
    language: LanguageCode = LanguageCode.ENGLISH
): Promise<void> => {
    toast(
        'Downloading report...',
        <IoCloudDownloadOutline color={infoToastColor} size={primaryIconSize} />
    )

    const reminder1Timeout = setTimeout(() => {
        toast(
            'Still working. Hang tight.',
            <IoCloudDownloadOutline
                color={infoToastColor}
                size={primaryIconSize}
            />
        )
    }, 5000)
    const reminder2Timeout = setTimeout(() => {
        toast(
            'This should be done soon.',
            <IoCloudDownloadOutline
                color={infoToastColor}
                size={primaryIconSize}
            />
        )
    }, 10000)

    const url = new URL(`${isa.defaults.baseURL ?? apiUrl}/export/insights`)
    if (query) {
        url.searchParams.set('type', query.type)
        url.searchParams.set('format', format)
        if (query.id) url.searchParams.set('query_id', query.id.toString())
        if (query.start_date) {
            url.searchParams.set(
                'start_date',
                new Date(query.start_date).toISOString()
            )
        }
        if (query.end_date) {
            url.searchParams.set(
                'end_date',
                new Date(query.end_date).toISOString()
            )
        }
        if (query.hazards)
            url.searchParams.set('hazards', query.hazards.toString())
        if (query.prep_checks)
            url.searchParams.set('prep_checks', query.prep_checks.toString())
        if (query.zips) url.searchParams.set('zips', query.zips.toString())
        url.searchParams.set('language', language)
        if (insights.length > 0)
            url.searchParams.set('insights', insights.join(','))
    }
    const response: AxiosResponse<Blob> = await isa.post(
        url.toString(),
        { grid_config: query?.grid_config },
        { responseType: 'blob' }
    )
    clearTimeout(reminder1Timeout)
    clearTimeout(reminder2Timeout)

    // create "a" HTML element with href to file & click
    const fileHref = URL.createObjectURL(response.data)
    const link = document.createElement('a')
    const filename = `${organization_slug}-${type}-insight-report-${Date.now()}`
    link.href = fileHref
    link.setAttribute('download', `${filename}.${format}`)
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(fileHref)
}

export const NON_BASIC_INSIGHTS = ['map', 'quick-stat']
export const isBasicInsight = (insight: InsightViewProps) =>
    !NON_BASIC_INSIGHTS.includes(insight.type)

/**
 * Ensure that local changes to insight pickers are preserved when insights are refreshed (since changes have not been saved yet)
 * @param newConfig New insight config dictionary
 * @param prevConfig Previous/existing insight config dictionary
 */
export const persistLocalPickerChangesOnNewConfig = (
    newConfig: InsightGridConfig,
    prevConfig: InsightGridConfig
) => {
    Object.keys(newConfig).forEach((k) => {
        Object.keys(newConfig[k].pickers).forEach((p) => {
            if (
                newConfig[k].pickers[p].options.some(
                    (o) =>
                        o.value === prevConfig[k]?.pickers[p]?.selected_option
                )
            ) {
                newConfig[k].pickers[p].selected_option =
                    prevConfig[k].pickers[p].selected_option
            }
        })
    })
}
