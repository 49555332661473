import { Search } from '@mui/icons-material'
import {
    TextField,
    OutlinedTextFieldProps,
    Typography,
    Autocomplete,
    Box,
    Grid,
} from '@mui/material'
import React, { FC } from 'react'

export interface SearchResultMenuItem {
    id: number
    icon: string
    name: string
}

interface SearchbarProps extends OutlinedTextFieldProps {
    options: SearchResultMenuItem[]
    onOptionPress(id: number): void
    loading?: boolean
    onQuerySubmit(): void
}

export const Searchbar: FC<SearchbarProps> = (props: SearchbarProps) => {
    const ref = React.useRef<HTMLInputElement>(null)
    const [optionsOpen, setOptionsOpen] = React.useState<boolean>(false)
    const [highlight, setHighlight] = React.useState<number | undefined>()

    React.useEffect(() => {
        if (props.value && (props.value as string).length > 0) {
            setOptionsOpen(true)
        }
    }, [props.value])

    return (
        <Autocomplete
            id="country-select-demo"
            sx={{ ...props.sx, flexGrow: 1 }}
            options={props.options}
            onHighlightChange={(_e, option, _reason) => {
                setHighlight(option?.id || undefined)
            }}
            filterOptions={() => props.options}
            loading={props.loading}
            getOptionLabel={(option) => option.name}
            includeInputInList
            renderOption={(params, option) => (
                <Box
                    {...params}
                    component="li"
                    sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                        paddingY: '1rem',
                    }}
                    onClick={() => {
                        ref.current?.blur()
                        props.onOptionPress(option.id)
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        paddingY="0.5rem"
                    >
                        <Grid
                            item
                            xs={2}
                            sm={1.5}
                            md={0.75}
                            display="flex"
                            justifyContent="center"
                        >
                            <img
                                src={`data:image/svg+xml;base64,${btoa(
                                    option.icon
                                )}`}
                                style={{
                                    width: '2rem',
                                    height: 'auto',
                                }}
                                alt={`${option.name} icon`}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10.5} md={11.25}>
                            <Typography variant="h4" fontWeight={400}>
                                {option.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
            // ListboxProps={{
            //     style: {
            //         display:
            //             (props.value as string).length > 0 ? 'block' : 'none',
            //     },
            // }}
            open={optionsOpen}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputRef={ref}
                    value={props.value}
                    onChange={props.onChange}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === 'Enter') {
                            if (highlight) {
                                props.onOptionPress(highlight)
                            } else {
                                props.onQuerySubmit()
                            }
                            setHighlight(undefined)
                            setOptionsOpen(false)
                        }
                    }}
                    onBlur={() => setOptionsOpen(false)}
                    placeholder={props.label?.toString() || 'Search hazards'}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            paddingLeft: '1rem',
                            paddingBottom: 0,
                            paddingTop: 0,
                        },
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: undefined,
                        startAdornment: <Search sx={{ paddingLeft: '1rem' }} />,
                        sx: {
                            ...props.sx,
                            borderRadius: '2rem',
                            paddingLeft: '1rem',
                            backgroundColor: 'white',
                        },
                    }}
                />
            )}
        />
    )
}
