import {
    Insight,
    InsightQueryBase,
    InsightQueryDTO,
    InsightQueryType,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import React, { FC, useMemo } from 'react'

import {
    HazardGuideInsightsPageTemplate,
    LoadingView,
    QueryBuilderValues,
} from '../components'
import { NamespacedPageProps, RefreshInsightArgs } from '../lib/entities'
import {
    useBuildInsightGridData,
    getInsights,
    refreshInsight,
    saveQuery,
    exportInsights,
    persistLocalPickerChangesOnNewConfig,
} from '../lib/utils/insights'
import { useAppSelector } from '../lib/store'
import { getHazardData } from '../lib/utils/cms'
import { buildZipCodePickerItems, formatDateRange } from '../lib/utils'
import { useSearchParams } from 'react-router-dom'

interface HazardGuideInsightsProps extends NamespacedPageProps {}

export const HazardGuideInsights: FC<HazardGuideInsightsProps> = ({
    user,
    organization,
    loading,
}) => {
    const [searchParams] = useSearchParams()

    // States
    const [insightData, setInsightData] = React.useState<Insight[]>()
    const [quickStatRowTitle, setQuickStatRowTitle] = React.useState<string>()
    const [activeQuery, setActiveQuery] = React.useState<InsightQueryDTO>()
    const [retrievingInsights, setRetrievingInsights] =
        React.useState<boolean>(true)

    // Store data
    const hazards = useAppSelector((state) => state.content.hazards)
    const hasLocalContent = useAppSelector(
        (state) => state.content.has_local_content
    )
    const selectedHazard = useMemo(() => {
        if (!activeQuery?.hazards || activeQuery.hazards.length === 0)
            return undefined
        const hazard = hazards.find((h) => h.id === activeQuery?.hazards?.[0])
        return hazard
    }, [activeQuery, hazards])

    const buildInsightGridData = useBuildInsightGridData()

    const refreshInsights = React.useCallback(
        async (
            existingQuery: InsightQueryDTO,
            reset = false,
            updatedParams = false
        ) => {
            setRetrievingInsights(true)
            getInsights(
                InsightQueryType.HAZARDS,
                undefined,
                existingQuery.id > 0 || updatedParams
                    ? existingQuery.start_date
                    : undefined,
                existingQuery.id > 0 || updatedParams
                    ? existingQuery.end_date
                    : undefined,
                existingQuery.hazards,
                existingQuery.prep_checks,
                existingQuery.zips,
                reset
            )
                .then((res) => {
                    const { insights, quick_stat_row_title, ...newQuery } = res
                    persistLocalPickerChangesOnNewConfig(
                        newQuery.grid_config,
                        existingQuery.grid_config
                    )
                    setActiveQuery(newQuery)
                    setInsightData(insights)
                    setQuickStatRowTitle(quick_stat_row_title)
                })
                .catch(console.error)
                .finally(() => setRetrievingInsights(false))
        },
        []
    )

    React.useEffect(() => {
        document.title = 'Hazard Guide Activity - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        getHazardData()
    }, [organization])

    React.useEffect(() => {
        getInsights(
            InsightQueryType.HAZARDS,
            searchParams,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [searchParams])

    const onSaveQuery = async () => {
        saveQuery(
            InsightQueryType.HAZARDS,
            activeQuery?.start_date,
            activeQuery?.end_date,
            activeQuery?.hazards,
            activeQuery?.prep_checks,
            activeQuery?.zips,
            activeQuery?.grid_config,
            activeQuery?.insight_ids
        )
    }

    const onRunQueryPress = React.useCallback(
        (values: QueryBuilderValues) => {
            if (!activeQuery || !user) return
            const active_query: InsightQueryDTO = {
                ...activeQuery,
                hazards: values.pickerOneValue
                    ? [parseInt(values.pickerOneValue.toString())]
                    : [],
                zips:
                    values.pickerTwoValue &&
                    values.pickerTwoValue !== 'jurisdiction'
                        ? [values.pickerTwoValue]
                        : [],
                start_date: values.start_date ?? undefined,
                end_date: values.end_date ?? undefined,
            }
            refreshInsights(active_query, undefined, true)
        },
        [activeQuery, refreshInsights, user]
    )

    const buildSelectedQueryString = () => {
        const selectedHazard = hazards.find(
            (h) => h.id === activeQuery?.hazards?.[0]
        )
        return `${selectedHazard?.name ?? 'All HazAdapt Hazard'} Guide Usage`
    }

    const refreshOneInsight = React.useCallback(
        async (
            placement_id: string,
            args: InsightQueryBase & RefreshInsightArgs
        ) => {
            args.grid_config = {
                ...args.grid_config,
                [placement_id]: {
                    ...args.grid_config[placement_id],
                    bbox: args.bbox,
                    zoom: args.zoom,
                },
            }
            delete args.bbox
            delete args.zoom
            setActiveQuery((aq) =>
                aq
                    ? {
                          ...aq,
                          ...args,
                      }
                    : undefined
            )
            const newInsight = await refreshInsight(
                placement_id,
                InsightQueryType.HAZARDS,
                args
            )
            const currInsights = [...(insightData ?? [])]
            const index = currInsights.findIndex(
                (i) => i.placement_id === placement_id
            )
            if (index < 0) currInsights.push(newInsight)
            else currInsights.splice(index, 1, newInsight)
            setInsightData(currInsights)
        },
        [insightData]
    )

    const insights = React.useMemo(
        () => buildInsightGridData(insightData, activeQuery, refreshOneInsight),
        [buildInsightGridData, insightData, activeQuery, refreshOneInsight]
    )

    const onReloadPress = React.useCallback(() => {
        if (!activeQuery) return
        refreshInsights(activeQuery, undefined, true)
    }, [activeQuery, refreshInsights])

    const onResetQueryPress = React.useCallback(() => {
        setRetrievingInsights(true)
        getInsights(
            InsightQueryType.HAZARDS,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            undefined,
            true
        )
            .then((res) => {
                const { insights, quick_stat_row_title, ...query } = res
                setInsightData(insights)
                setQuickStatRowTitle(quick_stat_row_title)
                setActiveQuery(query)
            })
            .catch(console.error)
            .finally(() => setRetrievingInsights(false))
    }, [])

    const hazardOptions: PickerItem<number | undefined>[] = useMemo(
        () => [
            { label: 'Select hazard...', value: undefined },
            ...[...hazards]
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((h) => ({ label: h.name, value: h.id })),
        ],
        [hazards]
    )

    return organization && user ? (
        <HazardGuideInsightsPageTemplate
            pageTitle={`Hazard Guide Activity`}
            organization={organization}
            selectedQueryItem={activeQuery?.hazards?.[0]}
            queryItems={hazardOptions}
            queryItemTitle="Hazard"
            queryItemSelectorPlaceholder="Select a hazard..."
            communityAreasForQueryBuilder={buildZipCodePickerItems(
                organization,
                false
            )}
            selectedArea={activeQuery?.zips?.[0] ?? 'jurisdiction'}
            areaSelectorPlaceholder="Select an area..."
            queryId={activeQuery?.id}
            startDate={
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : null
            }
            endDate={
                activeQuery?.end_date ? new Date(activeQuery.end_date) : null
            }
            onReloadPress={onReloadPress}
            updatedDate={
                activeQuery?.date_last_used
                    ? new Date(activeQuery.date_last_used)
                    : undefined
            }
            onRunQueryPress={onRunQueryPress}
            onResetQueryPress={onResetQueryPress}
            onSaveQueryPress={onSaveQuery}
            onDownloadPress={() =>
                exportInsights(
                    InsightQueryType.HAZARDS,
                    organization.slug,
                    activeQuery
                )
            }
            selectedQueryString={buildSelectedQueryString()}
            selectedLocationString={
                activeQuery?.zips?.join(', ') || 'Full Jurisdiction'
            }
            selectedDatesString={formatDateRange(
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : undefined,
                activeQuery?.end_date
                    ? new Date(activeQuery.end_date)
                    : undefined
            )}
            minQueryDate={new Date(organization.date_created)}
            insights={insights}
            quickStatRowTitle={quickStatRowTitle}
            loading={loading || retrievingInsights}
            showLocalContentDisclaimer={
                hasLocalContent && // There are local content
                activeQuery?.zips?.length === 0 && // Not searching over a specific ZIP code
                (!selectedHazard || !!selectedHazard.local) // No hazard selected, or hazard is a local hazard
            }
        />
    ) : (
        <LoadingView />
    )
}
