import { colors, TrendingHazardDTO } from '@hazadapt-git/public-core-base'
import { Box, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { FC, useEffect, useRef, useState } from 'react'

import { TrendingHazardItem } from '../molecules'
import {
    IoChevronBack,
    IoChevronForward,
    IoInformationCircle,
    IoTrendingUp,
} from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import classNames from 'classnames'
import { Popover } from '../atoms'
interface TrendingHazardListProps {
    data: TrendingHazardDTO[]
    showErrorText?: boolean
    onPress(id: number): void
    loading?: boolean
}

export const TrendingHazardList: FC<TrendingHazardListProps> = (
    props: TrendingHazardListProps
) => {
    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false)
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false)
    const [showScrollButtons, setShowScrollButtons] = useState<boolean>(false)
    const scrollRef = useRef<HTMLDivElement>(null)
    const mdScreens = useWindowSizeUp('md')
    const { classes: localClasses } = useLocalStyles()

    useEffect(() => {
        const handleScroll = () => {
            if (!props.loading && scrollRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } =
                    scrollRef.current

                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
            }
        }

        const scrollRefCurrent = scrollRef.current

        if (scrollRefCurrent) {
            handleScroll()
            scrollRefCurrent.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (scrollRefCurrent) {
                scrollRefCurrent.removeEventListener('scroll', handleScroll)
            }
        }
    }, [props.loading])

    useEffect(() => {
        if (mdScreens && (canScrollLeft || canScrollRight)) {
            setShowScrollButtons(true)
        } else {
            setShowScrollButtons(false)
        }
    }, [canScrollLeft, canScrollRight, mdScreens])

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            if (direction === 'left') {
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' })
            } else {
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' })
            }
        }
    }

    return (
        <Grid container className={localClasses.container} gap="0.5rem">
            <Grid item xs={12} className={localClasses.header}>
                <IoTrendingUp color={colors.secondary.HEAT_WAVE} size="2rem" />
                <Typography
                    variant="h4"
                    display="flex"
                    alignItems="center"
                    color={colors.primary.CERULEAN}
                >
                    Trending Hazards
                    <Popover
                        helpText="Trending Hazards highlight the Hazard Guides receiving the most interactions locally within the past week. Note: This may differ from HazAdapt's global trends."
                        icon={
                            <IoInformationCircle
                                color={colors.primary.CERULEAN}
                            />
                        }
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12} className={localClasses.listContainer}>
                {showScrollButtons && canScrollLeft && (
                    <IconButton
                        onClick={() => scroll('left')}
                        className={classNames(
                            localClasses.scrollButton,
                            localClasses.leftButton
                        )}
                    >
                        <IoChevronBack />
                    </IconButton>
                )}
                <Box className={localClasses.list} ref={scrollRef}>
                    {props.loading ? (
                        [...Array(5)].map((_, index) => (
                            <Skeleton
                                key={`trendingHazardItem_skeleton_${index + 1}`}
                                variant="rectangular"
                                className={localClasses.skeleton}
                            />
                        ))
                    ) : props.data.length < 1 ? (
                        <Typography sx={{ color: colors.grays.THUNDERCLOUD }}>
                            {props.showErrorText
                                ? 'Unable to load trending hazards.'
                                : 'Not enough data'}
                        </Typography>
                    ) : (
                        props.data.map((item, index) => (
                            <TrendingHazardItem
                                {...item}
                                onPress={() => props.onPress(item.id)}
                                fontShade="light"
                                key={`trendingHazardItem_${item.id}`}
                            />
                        ))
                    )}
                </Box>
                {showScrollButtons && canScrollRight && (
                    <IconButton
                        onClick={() => scroll('right')}
                        className={classNames(
                            localClasses.scrollButton,
                            localClasses.rightButton
                        )}
                    >
                        <IoChevronForward />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'grid',
        padding: '.25rem 0',
        margin: '.5rem',
        backgroundColor: colors.grays.BLANC,
        border: `1px solid ${colors.primary.CERULEAN}`,
        borderRadius: '.5rem',
        width: 'auto',
        boxSizing: 'border-box',
        minHeight: '7rem',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        marginLeft: '1rem',
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        overflowX: 'auto',
        padding: '0 1rem .5rem',
        gap: '1rem',
    },
    skeleton: { borderRadius: '.5rem', height: '2.75rem', width: '13rem' },
    listContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
    },
    scrollButton: {
        backgroundColor: colors.grays.SILK,
        height: '3rem',
        marginBottom: '.5rem',
        padding: '0',
        position: 'absolute',
        width: '1.25rem',
        ':hover': { backgroundColor: colors.grays.SILVER },
    },
    leftButton: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: '.5rem',
        borderTopLeftRadius: 0,
        borderTopRightRadius: '.5rem',
        marginRight: '-.75rem',
    },
    rightButton: {
        borderBottomLeftRadius: '.5rem',
        borderBottomRightRadius: 0,
        borderTopLeftRadius: '.5rem',
        borderTopRightRadius: 0,
        marginLeft: '-.75rem',
        right: '.55rem',
    },
})
