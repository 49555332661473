import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
    useMediaQuery,
    Tooltip,
} from '@mui/material'
import { GroupViewSwitch } from '../molecules/GroupViewSwitch'
import React, { FC, useMemo } from 'react'
import { GroupViewType } from '../../lib/entities'
import { RPUser, colors } from '@hazadapt-git/public-core-base'
import { TeamMemberView } from '../molecules/TeamMemberView'
import { makeStyles } from 'tss-react/mui'
import { canUserAddMembers, isUserAdmin, sortOrgMembers } from '../../lib/utils'
import { IoAdd } from 'react-icons/io5'
import { AddSeatsTriggerAndDrawer } from './AddSeatsTriggerAndDrawer'
import { customBorderRadius } from '../../lib/styles/universal'
import { useSubscription } from '../../lib/hooks/useSubscription'

export interface TeamMemberListProps {
    members: RPUser[]
    seats: number
    onAddUserClick(): void
    onResendInvite?(user: RPUser): void
    onRemoveUser(user: RPUser): void
    mode?: 'setup' | 'manage'
    activeUser: RPUser
    hideSkip?: boolean
    onOpenConfirmSkipSetupModal: React.MouseEventHandler
    onSaveUserChanges(user: RPUser): void
}

export const TeamMemberList: FC<TeamMemberListProps> = (
    props: TeamMemberListProps
) => {
    const [groupView, setGroupView] = React.useState<GroupViewType>('card')
    const seatsRemaining = props.seats - props.members.length
    const { classes: localClasses } = useLocalStyles()
    const theme = useTheme()
    const mdScreens = useMediaQuery(theme.breakpoints.up('md'))
    const { unresolvedSeatCount } = useSubscription()

    const adminUser = useMemo(
        () => isUserAdmin(props.activeUser),
        [props.activeUser]
    )

    const memberList = React.useCallback(() => {
        const members = [...props.members]
        return groupView === 'card' || !mdScreens ? (
            <Grid container spacing={2}>
                {members.sort(sortOrgMembers).map((user) => (
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        xl={2}
                        key={user.rp_user_id}
                    >
                        <TeamMemberView
                            user={user}
                            groupView="card"
                            onRemoveUser={props.onRemoveUser}
                            activeUser={props.activeUser}
                            onSaveChangesClick={props.onSaveUserChanges}
                            onResendInvite={props.onResendInvite}
                        />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight={500} textAlign="left">
                                Name
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={500}>Title</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={500}>
                                Email Address
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={500}>Date Added</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {members.map((user) => (
                        <TeamMemberView
                            key={user.rp_user_id}
                            user={user}
                            onRemoveUser={props.onRemoveUser}
                            groupView={groupView}
                            activeUser={props.activeUser}
                            onSaveChangesClick={props.onSaveUserChanges}
                            onResendInvite={props.onResendInvite}
                        />
                    ))}
                </TableBody>
            </Table>
        )
    }, [
        props.members,
        props.onRemoveUser,
        props.activeUser,
        props.onSaveUserChanges,
        props.onResendInvite,
        groupView,
        mdScreens,
    ])

    return (
        <Grid
            container
            columnSpacing={2}
            rowSpacing={1}
            className={localClasses.container}
            justifyContent={adminUser ? 'space-between' : 'flex-end'}
        >
            <Grid item xs={12}>
                <Typography
                    variant="h3"
                    component="h2"
                    color={colors.grays.NOIR}
                    fontWeight={500}
                >
                    {props.mode === 'setup' && 'Add '}
                    Team Members
                </Typography>
            </Grid>
            {mdScreens && (
                <Grid item xs={12}>
                    <GroupViewSwitch
                        state={groupView}
                        onSwitch={setGroupView}
                    />
                </Grid>
            )}
            <Grid item xs={12} className={localClasses.seats}>
                <Typography color={colors.primary.CERULEAN} fontWeight={500}>
                    {seatsRemaining} of {props.seats} seats remaining
                </Typography>
            </Grid>
            {adminUser && unresolvedSeatCount > 0 && (
                <div className={localClasses.unresolvedSeatsWarning}>
                    <Typography fontWeight={500}>Heads up!</Typography>
                    <Typography fontWeight={500}>
                        You have more team members than available seats.
                    </Typography>
                    <Typography>
                        Please remove {unresolvedSeatCount} more team member
                        {unresolvedSeatCount === 1 ? ' ' : 's '}from your
                        organization or purchase new seat subscriptions for
                        them.
                    </Typography>
                </div>
            )}
            {adminUser && (
                <Grid item xs="auto" order={2}>
                    <AddSeatsTriggerAndDrawer
                        variant="outlined"
                        buttonText="Buy More Seats"
                    />
                </Grid>
            )}
            {canUserAddMembers(props.activeUser) && (
                <Grid item xs="auto" order={mdScreens ? 3 : 2}>
                    <Tooltip
                        title="Please purchase more seats to add another team member"
                        disableHoverListener={seatsRemaining > 0}
                    >
                        <span>
                            <Button
                                onClick={props.onAddUserClick}
                                variant="contained"
                                startIcon={<IoAdd size="1.5rem" />}
                                disabled={seatsRemaining <= 0}
                            >
                                Add Team Member
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            )}
            <Grid item xs={12} order={5}>
                {memberList()}
            </Grid>
        </Grid>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        paddingTop: '1rem',
    },
    team: {
        marginLeft: '0',
    },
    sub: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyItems: 'center',
        gap: '1rem',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
    },
    labels: {
        justifyItems: 'center',
        flexGrow: 1,
        padding: '1rem 2rem',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    switch: {
        paddingBottom: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '2rem',
        width: '100%',
    },
    seats: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    unresolvedSeatsWarning: {
        backgroundColor: colors.secondary.HEAT_WAVE,
        borderRadius: customBorderRadius,
        padding: '1rem 1.5rem',
        maxWidth: '40rem',
    },
})
