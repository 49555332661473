import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import classNames from 'classnames'

interface LoadingViewProps {
    fullscreen?: boolean
}

export const LoadingView: FC<LoadingViewProps> = ({ fullscreen = true }) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <div
            className={classNames(localClasses.loading, {
                [localClasses.fullscreen]: fullscreen,
            })}
        >
            <CircularProgress size="1.5rem" />
            <Typography variant="h4">Loading...</Typography>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    loading: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 0',
        flex: 1,
    },
    fullscreen: {
        inset: 0,
        position: 'absolute',
        zIndex: -1,
    },
})
