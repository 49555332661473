import {
    Grid,
    OutlinedTextFieldProps,
    TextField as MuiTextField,
    Typography,
} from '@mui/material'
import React, { FC } from 'react'

interface TextFieldProps extends OutlinedTextFieldProps {
    icon?: React.ReactNode
}

export const TextField: FC<TextFieldProps> = (props: TextFieldProps) => (
    <Grid container spacing="1rem" alignItems="center" justifyContent="center">
        {props.label && (
            <Grid item xs={12}>
                <Typography variant="h4">{props.label}</Typography>
            </Grid>
        )}
        {props.icon && (
            <Grid
                item
                xs={1}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                {props.icon}
            </Grid>
        )}
        <Grid item xs={props.icon ? 11 : 12}>
            <MuiTextField {...props} label={undefined} fullWidth />
        </Grid>
    </Grid>
)
