import React, { FC, useEffect, useState } from 'react'
import { LoadingView, RootWelcomePageTemplate } from '../components'
import {
    GetRPOrganizationsResponse,
    RPOrganizationOverview,
    User,
} from '@hazadapt-git/public-core-base'
import { pruneString } from '../lib/utils'
import { PickerItem } from '../lib/entities'

interface RootWelcomePageProps {
    loading: boolean
    orgResponse: GetRPOrganizationsResponse | undefined
    publicUser: User | undefined
}

export const RootWelcomePage: FC<RootWelcomePageProps> = ({
    loading,
    orgResponse,
    publicUser,
}) => {
    const [allOrgsAsPickerOptions, setAllOrgsAsPickerOptions] = useState<
        PickerItem<string>[]
    >([])
    const [userOrgs, setUserOrgs] = useState<
        RPOrganizationOverview[] | undefined
    >()

    useEffect(() => {
        document.title = 'ResiliencePoint'
    }, [])

    useEffect(() => {
        if (orgResponse) {
            const orgsAsPickerOptions = orgResponse.organizations.map((org) => {
                return {
                    label: pruneString(org.name),
                    value: org.slug,
                    imageSrc: org.logo?.url ?? org.logo?.src,
                }
            })

            setAllOrgsAsPickerOptions(orgsAsPickerOptions)
            if (orgResponse.yours) {
                const filteredOrgs = orgResponse.organizations.filter((org) =>
                    orgResponse.yours?.includes(org.id)
                )
                setUserOrgs(filteredOrgs)
            }
        }
    }, [orgResponse])

    return loading ? (
        <LoadingView />
    ) : (
        <RootWelcomePageTemplate
            allOrgs={allOrgsAsPickerOptions}
            userOrgs={userOrgs}
            publicUser={publicUser}
        />
    )
}
