import {
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Dialog as MuiDialog,
} from '@mui/material'
import { FC } from 'react'
import { IoClose } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'
import { primaryIconSize } from '../../lib/styles/universal'
import { colors } from '@hazadapt-git/public-core-base'

interface DialogProps {
    id?: string
    title: string
    content: React.ReactNode
    compactTitle?: boolean
    ctas: React.ReactNode[]
    open?: boolean
    onClose?(): void
    width?: string | number
    maxWidth?: string | number
}

export const Dialog: FC<DialogProps> = (props: DialogProps) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <MuiDialog
            open={!!props.open}
            onClose={props.onClose}
            data-test-id={props.id}
            id={props.id}
            PaperProps={{
                sx: {
                    width: props.width,
                    maxWidth: `min(${props.maxWidth ?? '40rem'}, 90vw)`,
                },
            }}
        >
            <DialogTitle
                className={localClasses.header}
                variant={props.compactTitle ? 'h4' : 'h3'}
            >
                {props.title}
                {props.onClose && (
                    <IconButton onClick={props.onClose}>
                        <IoClose
                            size={primaryIconSize}
                            color={colors.grays.NOIR}
                        />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent className={localClasses.content}>
                {props.content}
            </DialogContent>
            <DialogActions className={localClasses.actions}>
                {props.ctas}
            </DialogActions>
        </MuiDialog>
    )
}

const useLocalStyles = makeStyles()({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        padding: '0.5rem 0 1rem 1rem',
    },
    content: {
        padding: '1.25rem 1rem 1rem',
    },
    actions: {
        padding: '0 1rem 0.75rem',
    },
})
