import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

interface BlockButtonProps {
    onPress(): void
    imageSrc: string
    imageAlt: string
    title: string
}

export const BlockButton: FC<BlockButtonProps> = (props: BlockButtonProps) => {
    const { classes: localClasses } = useLocalStyles()
    return (
        <button className={localClasses.button} onClick={() => props.onPress}>
            <img src={props.imageSrc} alt={props.imageAlt} />
            <Typography fontSize={20} fontWeight={400}>
                {props.title}
            </Typography>
        </button>
    )
}

const useLocalStyles = makeStyles()({
    button: {
        all: 'unset',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20rem',
        height: '21rem',
        cursor: 'pointer',
        borderRadius: '1rem',
    },
})
