import { colors, RPUser, User } from '@hazadapt-git/public-core-base'
import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { IoMenu } from 'react-icons/io5'
import { RPLogo } from '../atoms'
import { ProfileLinkWithAvatar } from '../molecules'

interface SiteHeaderProps {
    onLogoPress?: React.MouseEventHandler
    user?: RPUser
    publicUser?: User
    publicProfilePicture?: string
    onToggleMenu?(): void
    hideHamburgerMenu: boolean
}

export const SiteHeader: FC<SiteHeaderProps> = (props: SiteHeaderProps) => {
    const { classes: localClasses } = useLocalStyles()

    return (
        <div className={localClasses.siteHeader}>
            <RPLogo
                onClick={props.onLogoPress}
                className={localClasses.rpLogo}
                style={{ cursor: props.onLogoPress ? 'pointer' : 'default' }}
            />
            <div className={localClasses.navItems}>
                {props.hideHamburgerMenu ? null : (
                    <IconButton
                        onClick={props.onToggleMenu}
                        aria-label="Navigation drawer open icon"
                        sx={{
                            color: colors.grays.BLANC,
                        }}
                    >
                        <IoMenu />
                    </IconButton>
                )}
                {props.user && (
                    <ProfileLinkWithAvatar
                        src={props.user.profile_picture_uri ?? undefined}
                        name={props.user.first_name}
                    />
                )}
                {props.publicUser && !props.user && (
                    <ProfileLinkWithAvatar
                        src={props.publicProfilePicture ?? undefined}
                        name={props.publicUser.first_name}
                    />
                )}
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    siteHeader: {
        backgroundColor: colors.primary.CERULEAN,
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
        padding: '.5rem 1rem',
    },
    rpLogo: {
        height: '1.5rem',
        margin: '1rem 0',
        width: 'auto',
    },
    navItems: {
        display: 'flex',
    },
})
